import Button from 'react-bootstrap/Button';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';

import { authenticated } from '../../../../redux/features/authenticated/authenticatedSlice';
import { displayModal } from '../../../../redux/features/modal/modalSlice';

import Chorister from '../Chorister';

import './ChoristersContainer.css';

function ChoristersContainer({ choir }) {

  const isAuthenticated = useSelector(authenticated);

  const dispatch = useDispatch();

  const showAddChoristerModal = () => {
    const innerComponentName = 'AddChoristerForm';
    const backgroundInfo = { choir };

    dispatch(displayModal({ innerComponentName, backgroundInfo }));
  };

  const displayChoristers = () => {
    return choir.choristers.map(chorister => {
      return <Chorister
        key={ chorister.id }
        chorister={ chorister }
        choirName={ choir.name }
      />
    });
  };

  return (
    <div className='choristers-container'>
      {isAuthenticated && (
        <div className='chorister chorister-add'>
          <div className='chorister-image-and-name-container chorister-add-inner'>
            <Button variant='outline-primary' onClick={ showAddChoristerModal }>
              <FontAwesomeIcon icon={ faPlusCircle } />
            </Button>
            <p>Add</p>
          </div>
        </div>
      )}
      { displayChoristers() }
    </div>
  );
}

export default ChoristersContainer;
