import React, { useEffect, useRef, useState } from "react";

import { shallowEqual, useSelector } from "react-redux";

import Box from "@mui/material/Box";

import './TrackDetails.css';

let titleClientWidth = 0;

export default function TrackDetails(props) {

  const titleRef = useRef(null);

  const [isTextOverflowing, setIsTextOverflowing] = useState(false);
  const [scrollAnimationLength, setScrollAnimationLength] = useState('short');

  const { title, artist } = useSelector(
    /**@type {import("../redux/types").useSelectCb} */
    (state) => {
      let currentTrack = state.playlist[state.currentTrack];
      return {
        title: currentTrack.title,
        artist: currentTrack.artist,
      };
    },
    shallowEqual
  );

  useEffect(() => {
    const { clientWidth, scrollWidth } = titleRef.current;

    const isTextWiderThanContainer = (titleClientWidth || clientWidth) < scrollWidth;
    setIsTextOverflowing(isTextWiderThanContainer);

    // EVERYTHING MUST GO AFTER ABOVE LOGIC
    // OTHERWISE CLIENTWIDTH WILL BE
    // WIDTH OF TEXT, NOT CONTAINER
    // WHO TF KNOWS WHY
    if (title && titleClientWidth === 0) {
      titleClientWidth = clientWidth;
    }

    if (isTextWiderThanContainer) {
      const widthRatio = scrollWidth / titleClientWidth;

      if (widthRatio < 1.75) {
        setScrollAnimationLength('short');
      } else if (widthRatio < 2.5) {
        setScrollAnimationLength('medium');
      } else if (widthRatio < 3.25) {
        setScrollAnimationLength('long');
      } else {
        setScrollAnimationLength('extra-long');
      }
    }
  }, [title]);

  const sx = props.sx;

  return (
    <Box sx={sx}>
      <div className={
        `slider${isTextOverflowing
          ? ` scroll-text ${scrollAnimationLength}`
          : ''
        }`
      }>
        <Box
          className='track-details-title'
          ref={titleRef}
          sx={{
            typography: "subtitl3",
          }}
        >
          {title}
        </Box>
      </div>
      <Box
        sx={{
          typography: "subtitle2",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {artist}
      </Box>
    </Box>
  );
}
