import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import './ChooseSyllabus.css';

function ChooseSyllabus({ closeModal }) {

  return (
    <>
      <Modal.Title>Choose Syllabus</Modal.Title>
      <Modal.Body>
        <div className='syllabus-links'>
          <a
            href='https://docs.google.com/document/d/16p2cwr8EqD18ywuZrt1RqdO6BuVSMw9Syanbz3bVUqs'
            className='syllabus-link'
            target='_blank'
            rel='noopener noreferrer'
          >Treble Hawks</a>
          <a
            href='https://docs.google.com/document/d/16p2cwr8EqD18ywuZrt1RqdO6BuVSMw9Syanbz3bVUqs'
            className='syllabus-link'
            target='_blank'
            rel='noopener noreferrer'
          >Flight Crew</a>
          <a
            href='https://docs.google.com/document/d/14If9TFOz0aNw_2q0MvW6DaWAB4yWHyX-UG7UgsHm4N8'
            className='syllabus-link'
            target='_blank'
            rel='noopener noreferrer'
          >Nighthawk Honors</a>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={ closeModal }>
          Close
        </Button>
      </Modal.Footer>
    </>
  );
}

export default ChooseSyllabus;
