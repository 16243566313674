import { useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import imageCompression from 'browser-image-compression';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useDispatch, useSelector } from 'react-redux';

import { modalBackgroundInfo } from '../../../../redux/features/modal/modalSlice';
import { showAppAlert } from '../../../../redux/features/appAlert/appAlertSlice';
import {
  addSwag,
  swagsLength,
  updateSwag
} from '../../../../redux/features/swags/swagsSlice';

import SwagItem from '../SwagItem';

import { apiUrl, nth } from '../../../../utilities';

import {
  storage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject
} from '../../../../firebase/firebase';

import './AddOrEditSwagItemForm.css';

function AddOrEditSwagItemForm({ closeModal }) {

  const dispatch = useDispatch();

  const { swag = {} } = useSelector(modalBackgroundInfo);
  const isExistingSwag = !!swag.id;

  const numberOfSwags = useSelector(swagsLength);

  const [busy, setBusy] = useState(false);

  const [imageFile, setImageFile] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [fileProgress, setFileProgress] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);

  const [imageUrlPreview, setImageUrlPreview] = useState(swag.image || '');

  const [group, setGroup] = useState(swag.group || '');
  const [category, setCategory] = useState(swag.category || '');
  const [order, setOrder] = useState(swag.order || (numberOfSwags + 1));

  const imageName = `${group} ${category}`;

  const swagUpdates = {
    image: imageUrlPreview,
    group,
    category,
    order
  };

  const clearImage = () => {
    setImageFile('');
    setImageUrlPreview('');
  };

  const startProcessing = () => {
    setBusy(true);
    setIsProcessing(true);
  };

  const resetProcessing = () => {
    setBusy(false);
    setIsProcessing(false);
    setFileProgress(0);
  };

  const processingFailed = errorMessage => {
    dispatch(showAppAlert({ message: errorMessage }));
    resetProcessing();
  };

  const handleCompressionProgress = percentComplete => {
    setFileProgress(percentComplete);
    if (percentComplete === 100) resetProcessing();
  };

  const handleImageFile = async event => {
    const file = event.target.files[0];
    if (file instanceof File && file?.type.includes('image')) {
      const compressionOptions = {
        maxSizeMB: 0.1,
        maxIteration: 50,
        onProgress: handleCompressionProgress,
        useWebWorker: true
      };
      try {
        startProcessing();
        const compressedFile = await imageCompression(file, compressionOptions);

        setImageFile(compressedFile);
        const urlCreator = window.URL || window.webkitURL;
        setImageUrlPreview(urlCreator.createObjectURL(compressedFile));
      } catch (error) {
        processingFailed(
          `Please try again. Error compressing image: ${error.message}`
        );
      }
    } else if (file) {
      clearImage();
      dispatch(showAppAlert({
        message: `Not an image, the file is a ${file.type}.`
      }));
    } else {
      clearImage();
      dispatch(showAppAlert({ message: 'Please select a valid file.' }));
    }
  };

  const handleUploadStateChange = snapshot => {
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    setFileProgress(progress);

    switch (snapshot.state) {
      case 'paused':
        dispatch(showAppAlert({ message: 'Upload is paused' }));
        break;
      case 'running':
        break;
      default:
        break;
    }
  };

  const handleUploadError = (error, reject) => {
    const errorMessage = `Error uploading image: ${error.message}`;
    reject({ message: errorMessage });
  };

  const handleUploadComplete = async (uploadTask, resolve, reject) => {
    try {
      const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
      resolve(downloadUrl);
    } catch (error) {
      const errorMessage =
        `Please try again. Error retrieving image url: ${error.message}`;
      reject({ message: errorMessage });
    }
  };

  const handleFireBaseUpload = async () => {
    if (isExistingSwag) {
      const firebaseDeleteStorageUrl = `/swag/${swag.category} - ${swag.group}`;
      const existingImageRef = ref(storage, firebaseDeleteStorageUrl);
      await deleteObject(existingImageRef);
    }

    const firebaseUploadStorageUrl = `/swag/${category} - ${group}`;
    const storageRef = ref(storage, firebaseUploadStorageUrl);
    const uploadTask = uploadBytesResumable(storageRef, imageFile);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed', 
        handleUploadStateChange,
        (error) => handleUploadError(error, reject),
        () => handleUploadComplete(uploadTask, resolve, reject)
      );
    });
  };

  const makeUpdateSwagRequestOptions = firebaseImageUrl => ({
    method: isExistingSwag ? 'PATCH' : 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      ...swagUpdates,
      image: firebaseImageUrl
    })
  });

  const isImageChosen = imageUrlPreview !== swag.image;
  const isGroupOrCategoryChange = group !== swag.group || category !== swag.category;

  const handleSaveSwagImage = async event => {
    event.preventDefault();

    startProcessing();

    try {
      let firebaseImageUrl = swag.image;
      if (isImageChosen || isGroupOrCategoryChange) {
        firebaseImageUrl = await handleFireBaseUpload();
      }

      const swagUrl = `${apiUrl}/swags` + (isExistingSwag ? `/${swag.id}` : '');
      const saveOptions = makeUpdateSwagRequestOptions(firebaseImageUrl);
      const response = await fetch(
        swagUrl,
        saveOptions
      );
      const {
        swag: savedSwag,
        order_updates: orderUpdates
      } = await response.json();

      if (savedSwag.id) {
        isExistingSwag
          ? dispatch(updateSwag({ updatedSwag: savedSwag, orderUpdates }))
          : dispatch(addSwag({ newSwag: savedSwag, orderUpdates }));

        dispatch(showAppAlert({
          title: 'Success',
          message: `${imageName}, ${isExistingSwag ? 'updated' : 'added'}`,
          bg: 'success'
        }));
        setTimeoutId(setTimeout(closeModal, 200));
      } else {
        const errorMessage = savedSwag.errors
          .reduce((errorString, error, index) => {
            return errorString += `${index + 1}. ${error}\n`;
          }, '');
        processingFailed(errorMessage);
      }

    } catch (error) {
      setTimeoutId(setTimeout(() => processingFailed(error.message), 200));
    }
  };

  useEffect(() => {
    const removeTimeout = () => timeoutId && clearTimeout(timeoutId);
    return removeTimeout;
  }, [timeoutId]);

  const isAnyFieldEmpty = !imageUrlPreview ||
    !order ||
    !group ||
    !category;
  const maxPosition = isExistingSwag ? numberOfSwags : (numberOfSwags + 1);
  const isOrderOneToMaxPosition = order >= 1 && order <= maxPosition;
  const isNameChangedAndImageNotChosen = isGroupOrCategoryChange && !isImageChosen;

  const isAnyFieldInvalid = isAnyFieldEmpty ||
    !isOrderOneToMaxPosition ||
    isNameChangedAndImageNotChosen;

  return (
    <section className='add-or-edit-swag-item-form-container'>
      <h2 className='swag-item-number'>
        { order }{ nth(order) } Position
      </h2>

      <SwagItem swag={ swagUpdates } isPreview />

      <Form className='add-or-edit-swag-item-form'>
        <Form.Group className='mb-3' controlId='formOrder'>
          <Form.Label>Position</Form.Label>
          <Form.Control
            isInvalid={ !order || !isOrderOneToMaxPosition }
            isValid={ order && isOrderOneToMaxPosition }
            type='number'
            min='1'
            max={ maxPosition }
            placeholder='Position'
            value={ order }
            onChange={ event => setOrder(event.target.value) }
            onWheel={ event => event.target.blur() }
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formGroup'>
          <Form.Label>Choir Group</Form.Label>
          <Form.Control
            isInvalid={ !group }
            isValid={ group }
            type='text'
            placeholder='Choir group'
            value={ group }
            onChange={ event => setGroup(event.target.value) }
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formCategory'>
          <Form.Label>Item Category</Form.Label>
          <Form.Control
            isInvalid={ !category }
            isValid={ category }
            type='text'
            placeholder='Item category'
            value={ category }
            onChange={ event => setCategory(event.target.value) }
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formPhotoUpload'>
          <Form.Label>Select Photo</Form.Label>
          <Form.Control
            isInvalid={ !imageUrlPreview || isNameChangedAndImageNotChosen }
            isValid={ imageUrlPreview || !isNameChangedAndImageNotChosen }
            type='file'
            accept='image/*'
            onChange={ handleImageFile }
          />
          <Form.Control.Feedback type='invalid'>
            Please upload image
          </Form.Control.Feedback>
        </Form.Group>

        <Button
          disabled={ isAnyFieldInvalid || busy }
          variant='primary'
          type='submit'
          onClick={ handleSaveSwagImage }
        >
          Save <FontAwesomeIcon icon={ faSave } />
        </Button>

        {isProcessing && (
          <ProgressBar animated now={ fileProgress } />
        )}
      </Form>
    </section>
  );
}

export default AddOrEditSwagItemForm;
