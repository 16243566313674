import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { displayModal } from '../../redux/features/modal/modalSlice';

import './Footer.css';

function Footer() {

  const dispatch = useDispatch();

  const openResourcesModal = () => {
    const innerComponentName = 'ChooseResource';
    dispatch(displayModal({ innerComponentName }));
  };

  const openSyllabusModal = () => {
    const innerComponentName = 'ChooseSyllabus';
    dispatch(displayModal({ innerComponentName }));
  };

  return (
    <footer>
      <ul className='footer-list'>
        <li>
          Copyright © 2023 Denver Public Schools
        </li>

        <li className='footer-list-separator'>|</li>

        <li className='footer-button' onClick={ openResourcesModal }>
          Resources
        </li>

        <li className='footer-list-separator'>|</li>

        <li className='footer-button' onClick={ openSyllabusModal }>
          Syllabus
        </li>
      </ul>

      <div className="made-by">
        Made by Ahmed
        <span>
          <Link className='login-link' to='steele'>
            {' '}Gaber
          </Link>
        </span>
      </div>
    </footer>
  );
}

export default Footer;
