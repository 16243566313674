import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getStaff, staffInfo } from '../../../redux/features/staff/staffSlice';

import ContactInfo from './ContactInfo';
import Loading from '../../generic/Loading';

import './Contact.css';

function Contact() {

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const dispatch = useDispatch();
  const { isLoading, staffMembers } = useSelector(staffInfo);

  useEffect(() => {
    if (!staffMembers.length) {
      dispatch(getStaff());
    }
  }, [dispatch, staffMembers.length]);

  return (
    <>
      {isLoading
        ? <Loading />
        : (
          <section className='contact'>
            <h2 className='contact-heading'>Contact Us</h2>
            <ContactInfo staffMembers={ staffMembers } />
          </section>
        )
      }
    </>
  );
}

export default Contact;
