import { useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import { faAsterisk, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { cartItemsFromStore } from '../../../redux/features/cart/cartSlice';
import { showAppAlert } from '../../../redux/features/appAlert/appAlertSlice';

import CheckoutItem from './CheckoutItem';

import { apiUrl, capitalize } from '../../../utilities';

import './Checkout.css';

function Checkout() {

  const dispatch = useDispatch();

  const cartItems = useSelector(cartItemsFromStore);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [validated, setValidated] = useState(false);

  const [isSendingEmail, setIsSendingEmail] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const cartItemsStringForEmail = () => {
    return cartItems.map((cartItem, index) => {
      const cartItemCategoryCapitalized = capitalize(cartItem.category);
      return `${index + 1}) ${cartItem.group} ${cartItemCategoryCapitalized}
        Size: ${cartItem.size}
        Quantity: ${cartItem.quantity}

      `
    });
  };

    const emailBody = {
    from: `Order`,
    subject: `Website Swag Order`,
    content: `From: ${firstName} ${lastName}

Email: ${email}

Message:
${message || '(empty)'}

${cartItemsStringForEmail}

    `
  };

  const makeCheckoutRequestOptions = () => ({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email: emailBody })
  });

  const resetForm = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setMessage('');
    setValidated(false);
  };

  const handleSendEmail = async event => {
    event.preventDefault();
    
    const form = event.target.closest('.order-form');
    if (!form.checkValidity()) {
      setValidated(true);
      return;
    }

    setIsSendingEmail(true);
    try {
      const createOptions = makeCheckoutRequestOptions();
      const response = await fetch(`${apiUrl}/email`, createOptions);
      const emailResult = await response.json();

      if (emailResult.status_code === '202') {
        resetForm();
        dispatch(showAppAlert({
          title: 'Success',
          message: 'Your order email has been sent!',
          bg: 'success'
        }));
      } else {
        const errorMessage = 'Please try again, our owl did not deliver your email order.';
        dispatch(showAppAlert({ message: errorMessage }));
      }

    } catch (error) {
      const errorMessage =
        `Please try again. Owl failed delivering your email order because: ${error.message}`;
      dispatch(showAppAlert({ message: errorMessage }));
    } finally {
      setIsSendingEmail(false);
    }
  };

  const displayCartItems = () => {
    return cartItems.map((cartItem, index) => {
      return (
        <CheckoutItem
          key={ `${cartItem.id} ${index}` }
          cartItem={ { ...cartItem, index } }
        />
      );
    });
  };

  const isCartEmpty = cartItems.length === 0;

  const isAnyFieldEmpty = !firstName ||
    !lastName ||
    !email ||
    isCartEmpty;

  return (
    <section className='checkout'>
      <h2 className='checkout-heading'>Checkout</h2>

      <div
        className={ `checkout-cart-items-container${isCartEmpty ? ' is-empty' : ''}` }
      >
        {isCartEmpty
          ? <Link
            to='/swag-&-support'
            role='swag and support link'
            className='swag-support-button-link'
          >
            <Button className='go-to-swag-button'>See our swag</Button>
          </Link>
          : displayCartItems()
        }
      </div>

      <Form
        noValidate
        validated={ validated }
        className='order-form'
        aria-describedby='orderFormPurchaseNotes'
      >
        <Form.Group className='mb-3' controlId='formFirstName'>
          <Form.Label className='required'>
            First Name<FontAwesomeIcon icon={ faAsterisk } />
          </Form.Label>
          <Form.Control
            required
            type='text'
            placeholder='First name'
            value={ firstName }
            onChange={ event => setFirstName(event.target.value) }
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formLastName'>
          <Form.Label className='required'>
            Last Name<FontAwesomeIcon icon={ faAsterisk } />
          </Form.Label>
          <Form.Control
            required
            type='text'
            placeholder='Last name'
            value={ lastName }
            onChange={ event => setLastName(event.target.value) }
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formEmail'>
          <Form.Label className='required'>
            Email<FontAwesomeIcon icon={ faAsterisk } />
          </Form.Label>
          <Form.Control
            required
            type='email'
            placeholder='your@email.com'
            value={ email }
            onChange={ event => setEmail(event.target.value) }
          />
          <Form.Control.Feedback type='invalid'>
            Please provide a valid email.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className='mb-3' controlId='formDetails'>
          <Form.Label>
            Details
          </Form.Label>
          <Form.Control
            type='text'
            as='textarea'
            rows={ 3 }
            placeholder='about this order...'
            value={ message }
            onChange={ event => setMessage(event.target.value) }
          />
        </Form.Group>

        <Form.Text id="orderFormPurchaseNotes" muted>
          We will email you soon to confirm and pay.
          You can modify or cancel your order then.
          Thank you!
        </Form.Text>

        <Button
          disabled={ isAnyFieldEmpty || isSendingEmail }
          variant='primary'
          className='submit-order-button'
          onClick={ handleSendEmail }
        >
          {isSendingEmail
            ? 'Sending...'
            : 'Submit Order'
          } <FontAwesomeIcon icon={ faPaperPlane } />
        </Button>
      </Form>
    </section>
  );
}

export default Checkout;
