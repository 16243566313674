import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import NCNavLink from './NCNavLink';

import './NCNav.css';

const pathNames = [
  'choirs',
  'staff',
  'events',
  'media',
  'swag & support',
  'contact'
];

function NCNav() {

  const displayNavLinks = () => {
    return pathNames.map((pathName, index) => {
      return <NCNavLink
        key={ index + 1 }
        pathName={ pathName }
        order={ index + 1 }
      />
    });
  }

  return (
    <Navbar.Collapse
      id="basic-navbar-nav"
      className="header-nav"
      role="navigation"
      aria-label="Main menu"
    >
      <Nav className="nav-links">
        { displayNavLinks() }
      </Nav>
    </Navbar.Collapse>
  );
}

export default NCNav;
