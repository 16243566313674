import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: '',
  message: '',
  bg: '',
  isAutohide: true,
  delay: 3500,
  timeoutId: undefined
};

export const showAppAlert = createAsyncThunk(
  'appAlert/reset',
  async (payload, thunkAPI) => {
    const resetAppAlertPromise = new Promise(resolve => {
      const timeoutId = setTimeout(resolve, 0);
      thunkAPI.dispatch(resetAppAlert(timeoutId));
    });

    await resetAppAlertPromise;
    thunkAPI.dispatch(setAppAlert(payload));
    return;
  }
);

export const appAlertSlice = createSlice({
  name: 'appAlert',
  initialState,
  reducers: {
    setAppAlert(state, action) {
      const {
        title,
        message,
        bg,
        isAutohide,
        delay
      } = action.payload;
      state.message = message;
      title && (state.title = title);
      bg && (state.bg = bg);
      (isAutohide || isAutohide === false) && (state.isAutohide = isAutohide);
      delay && (state.delay = delay);
    },
    resetAppAlert(state, action) {
      state.timeoutId && clearTimeout(state.timeoutId);
      state.timeoutId = action.payload;

      state.title = initialState.title;
      state.message = initialState.message;
      state.bg = initialState.bg;
      state.isAutohide = initialState.isAutohide;
      state.delay = initialState.delay;
    }
  },
  extraReducers: builder => {
    builder.addCase(
      showAppAlert.fulfilled,
      () => {}
    );
  }
});

export const appAlertInfo = state => state.appAlert;

export const { resetAppAlert, setAppAlert } = appAlertSlice.actions;

export default appAlertSlice.reducer;
