import { useEffect, useRef, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  getHomeGalleryPhotos,
  homeGalleryPhotosInfo
} from '../../../../redux/features/homepage/homeGalleryPhotosSlice';

import HomeGalleryPhoto from './HomeGalleryPhoto';
import Loading from '../../../generic/Loading';

import './HomeGallery.css';

function HomeGallery() {

  const dispatch = useDispatch();
  const { isLoading, homeGalleryPhotos } = useSelector(homeGalleryPhotosInfo);

  const titleRef = useRef();
  const [isTitleVisible, setIsTitleVisible] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      const handleObserver = entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsTitleVisible(true);
            elementsObserver.unobserve(titleRef.current);
          }
        });
      };
      const observeOptions = { threshold: 0.50 };
      const elementsObserver = new IntersectionObserver(
        handleObserver,
        observeOptions
      );
      elementsObserver.observe(titleRef.current);
  
      return () => elementsObserver.disconnect();
    }
  }, [isLoading]);

  useEffect(() => {
    if (!homeGalleryPhotos.length) {
      dispatch(getHomeGalleryPhotos());
    }
  }, [dispatch, homeGalleryPhotos.length]);

  const displayHomeGalleryPhotos = () => {
    return homeGalleryPhotos.map(homeGalleryPhoto => {
        return (
          <HomeGalleryPhoto
            key={ homeGalleryPhoto.id }
            homeGalleryPhoto={ homeGalleryPhoto }
          />
        );
    });
  };

  return (
    <div id='gallery' className='home-gallery'>
      <h3
        ref={ titleRef }
        className={`gallery-title${ isTitleVisible ? ' is-visible' : '' }`}
      >
        What makes choir so great?
      </h3>
      {isLoading
        ? <Loading />
        : <div className='gallery-items'>
          { displayHomeGalleryPhotos() }
        </div>
      }
    </div>
  );
}

export default HomeGallery;
