import { useEffect, useRef, useState } from 'react';

import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

import './HomeChoirs.css';

import flightCrewLogo from '../../../../assets/images/flight-crew-logo.png';
import trebleHawksLogo from '../../../../assets/images/treble-hawks-logo.png';
import nighthawkHonorsLogo from '../../../../assets/images/nighthawk-honors-logo.png';

function HomeChoirs() {

  const titleRef = useRef();
  const imageRef = useRef();

  const [isTitleVisible, setIsTitleVisible] = useState(false);
  const [isFirstImageVisible, setIsFirstImageVisible] = useState(false);

  useEffect(() => {
    const handleObserver = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (entry.target.className.includes('title')) {
            setIsTitleVisible(true);
            elementsObserver.unobserve(titleRef.current);
          } else if (entry.target.className.includes('image')) {
            setIsFirstImageVisible(true);
            elementsObserver.unobserve(imageRef.current);
          }
        }
      });
    };
    const observeOptions = { threshold: 0.50 };
    const elementsObserver = new IntersectionObserver(
      handleObserver,
      observeOptions
    );
    elementsObserver.observe(titleRef.current);
    elementsObserver.observe(imageRef.current);

    return () => elementsObserver.disconnect();
  }, []);

  return (
    <div id='choirs' className='home-choirs'>
      <h3
        ref={ titleRef }
        className={`home-choirs-title${ isTitleVisible ? ' is-visible' : '' }`}
      >
        Our choirs
      </h3>

      <div className='home-choirs-list'>
        <div className='home-choir-container'>
          <Link
            to='choirs/flight-crew'
            role='flight crew choir link'
            className='choir-link'
          >
            <img
              className={`home-choir-image${ isFirstImageVisible ? ' is-visible' : '' }`}
              ref={ imageRef }
              src={ flightCrewLogo }
              alt='flight crew choir logo'
            />
          </Link>
          <p className={`home-choir-description${ isFirstImageVisible ? ' is-visible' : '' }`}>
            Flight Crew, our beginner, no audition, no experience needed class for bass singers
          </p>
        </div>
        <div className='home-choir-container'>
          <Link
            to='choirs/treble-hawks'
            role='treble hawks choir link'
            className='choir-link'
          >
            <img
              className={`home-choir-image${ isFirstImageVisible ? ' is-visible' : '' }`}
              src={ trebleHawksLogo }
              alt='treble hawks choir logo'
            />
          </Link>
          <p className={`home-choir-description${ isFirstImageVisible ? ' is-visible' : '' }`}>
            Treble Hawks, our beginner, no audition, no experience needed class for treble singers
          </p>
        </div>
        <div className='home-choir-container'>
          <Link
            to='choirs/nighthawk-honors'
            role='nighthawk honors choir link'
            className='choir-link nighthawk-honors-logo-link'
          >
            <img
              className={`home-choir-image${ isFirstImageVisible ? ' is-visible' : '' }`}
              src={ nighthawkHonorsLogo }
              alt='nighthawk honors choir logo'
            />
          </Link>
          <p className={`home-choir-description${ isFirstImageVisible ? ' is-visible' : '' }`}>
            Nighthawk Honors, our top choir class - by audition only
          </p>
        </div>
      </div>

      <Link
        to='choirs'
        role='choirs link'
        className={`choirs-link${isFirstImageVisible ? ' is-visible' : ''}`}
      >
        <Button
          className='go-to-choirs-button'
          variant='outline-warning'
          role='link'
        >
          See our choirs
        </Button>
      </Link>
    </div>
  );
}

export default HomeChoirs;
