import { useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import imageCompression from 'browser-image-compression';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useDispatch, useSelector } from 'react-redux';

import { modalBackgroundInfo } from '../../../../../redux/features/modal/modalSlice';
import { showAppAlert } from '../../../../../redux/features/appAlert/appAlertSlice';
import { updateHomeGalleryPhotoAttribute } from '../../../../../redux/features/homepage/homeGalleryPhotosSlice';

import { apiUrl } from '../../../../../utilities';

import {
  storage,
  ref,
  uploadBytesResumable,
  getDownloadURL
} from '../../../../../firebase/firebase';

import './EditHomeGalleryPhotoForm.css';

function EditHomeGalleryPhotoForm({ closeModal }) {

  const dispatch = useDispatch();

  const { homeGalleryPhoto } = useSelector(modalBackgroundInfo);

  const [busy, setBusy] = useState(false);
  
  const [imageFile, setImageFile] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [fileProgress, setFileProgress] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);

  const [imageUrlPreview, setImageUrlPreview] = useState(homeGalleryPhoto.photo || '');

  const homeGalleryPhotoUpdates = {
    photo: imageUrlPreview,
  };

  const clearImage = () => {
    setImageFile('');
    setImageUrlPreview('');
  };

  const startProcessing = () => {
    setBusy(true);
    setIsProcessing(true);
  };

  const resetProcessing = () => {
    setBusy(false);
    setIsProcessing(false);
    setFileProgress(0);
  };

  const processingFailed = errorMessage => {
    dispatch(showAppAlert({ message: errorMessage }));
    resetProcessing();
  };

  const handleCompressionProgress = percentComplete => {
    setFileProgress(percentComplete);
    if (percentComplete === 100) resetProcessing();
  };

  const handleImageFile = async event => {
    const file = event.target.files[0];
    if (file instanceof File && file?.type.includes('image')) {
      const compressionOptions = {
        maxSizeMB: 0.3,
        maxIteration: 50,
        onProgress: handleCompressionProgress,
        useWebWorker: true
      };
      try {
        startProcessing();
        const compressedFile = await imageCompression(file, compressionOptions);

        setImageFile(compressedFile);
        const urlCreator = window.URL || window.webkitURL;
        setImageUrlPreview(urlCreator.createObjectURL(compressedFile));
      } catch (error) {
        processingFailed(
          `Please try again. Error compressing image: ${error.message}`
        );
      }
    } else if (file) {
      clearImage();
      dispatch(showAppAlert({
        message: `Not an image, the file is a ${file.type}.`
      }));
    } else {
      clearImage();
      dispatch(showAppAlert({ message: 'Please select a valid file.' }));
    }
  };

  const handleUploadStateChange = snapshot => {
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    setFileProgress(progress);

    switch (snapshot.state) {
      case 'paused':
        dispatch(showAppAlert({ message: 'Upload is paused' }));
        break;
      case 'running':
        break;
      default:
        break;
    }
  };

  const handleUploadError = (error, reject) => {
    const errorMessage = `Error uploading image: ${error.message}`;
    reject({ message: errorMessage });
  };

  const handleUploadComplete = async (uploadTask, resolve, reject) => {
    try {
      const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
      resolve(downloadUrl);
    } catch (error) {
      const errorMessage =
        `Please try again. Error retrieving image url: ${error.message}`;
      reject({ message: errorMessage });
    }
  };

  const handleFireBaseUpload = () => {
    const storageRef = ref(
      storage,
      `/homepage/home_gallery_photos/${homeGalleryPhoto.order}`
    );

    const uploadTask = uploadBytesResumable(storageRef, imageFile);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed', 
        handleUploadStateChange,
        (error) => handleUploadError(error, reject),
        () => handleUploadComplete(uploadTask, resolve, reject)
      );
    });
  }

  const makeUpdateHomeGalleryPhotoRequestOptions = firebaseImageUrl => ({
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      ...homeGalleryPhotoUpdates,
      photo: firebaseImageUrl
    })
  });

  const handleUpdateHomeGalleryPhoto = async event => {
    event.preventDefault();

    startProcessing();

    try {
      let firebaseImageUrl = homeGalleryPhoto.photo;
      if (imageUrlPreview !== homeGalleryPhoto.photo) {
        firebaseImageUrl = await handleFireBaseUpload();
      }

      const editOptions = makeUpdateHomeGalleryPhotoRequestOptions(firebaseImageUrl);
      const response = await fetch(
        `${apiUrl}/home_gallery_photos/${homeGalleryPhoto.id}`,
        editOptions
      );
      const updatedHomeGalleryPhoto = await response.json();

      if (updatedHomeGalleryPhoto.id) {
        dispatch(updateHomeGalleryPhotoAttribute({
          homeGalleryPhotoId: updatedHomeGalleryPhoto.id,
          attribute: 'photo',
          newValue: updatedHomeGalleryPhoto.photo
        }));
        dispatch(showAppAlert({
          title: 'Success',
          message: `Photo, #${updatedHomeGalleryPhoto.order}, updated`,
          bg: 'success'
        }));
        setTimeoutId(setTimeout(closeModal, 200));
      } else {
        const errorMessage = updatedHomeGalleryPhoto.errors
          .reduce((errorString, error, index) => {
            return errorString += `${index + 1}. ${error}\n`;
          }, '');
        processingFailed(errorMessage);
      }

    } catch (error) {
      setTimeoutId(setTimeout(() => processingFailed(error.message), 200));
    }
  };

  const isAnyFieldEmpty = !imageUrlPreview;

  useEffect(() => {
    const removeTimeout = () => timeoutId && clearTimeout(timeoutId);
    return removeTimeout;
  }, [timeoutId]);

  return (
    <section className='edit-home-gallery-photo-form-container'>
      <h2 className='home-gallery-photo-number'>
        Photo #{ homeGalleryPhoto.order }
      </h2>

      <h2 className='home-gallery-photo-title'>{ homeGalleryPhoto.title }</h2>

      <div className='home-gallery-photo-image-container'>
        <img
          className='home-gallery-photo-image'
          src={ homeGalleryPhotoUpdates.photo }
          alt={ `home gallery ${homeGalleryPhoto.order}`}
        />
      </div>

      <Form className='edit-home-gallery-photo-form'>
        <Form.Group className='mb-3' controlId='formPhotoUpload'>
          <Form.Label>Select Photo</Form.Label>
          <Form.Control
            isInvalid={ !imageUrlPreview }
            isValid={ imageUrlPreview }
            type='file'
            accept='image/*'
            onChange={ handleImageFile }
          />
        </Form.Group>

        <Button
          disabled={ isAnyFieldEmpty || busy }
          variant='primary'
          type='submit'
          onClick={ handleUpdateHomeGalleryPhoto }
        >
          Save <FontAwesomeIcon icon={ faSave } />
        </Button>
        {isProcessing && (
          <ProgressBar animated now={ fileProgress } />
        )}
      </Form>
    </section>
  );
}

export default EditHomeGalleryPhotoForm;
