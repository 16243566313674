import { useDispatch, useSelector } from 'react-redux';

import { authenticated } from '../../../../redux/features/authenticated/authenticatedSlice';
import { displayModal } from '../../../../redux/features/modal/modalSlice';

import DeleteButtonWithTooltip from '../../../generic/DeleteButtonWithTooltip';

import './Chorister.css';

function Chorister({ chorister, choirName, isPreview }) {

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(authenticated);

  const fullName = `${chorister.first_name} ${chorister.last_name}`;

  const confirmChoristerDelete = () => {
    dispatch(displayModal({
      innerComponentName: 'Confirm',
      backgroundInfo: {
        handleConfirmName: 'handleChoristerDelete',
        isDelete: true,
        message: `Remove ${fullName} permanently?`,
        details: {
          choirName,
          chorister
        }
      }
    }));
  };

  return (
    <div className='chorister'>
      <div className='chorister-image-and-name-container'>
        {isAuthenticated && !isPreview && (
          <DeleteButtonWithTooltip
            itemName='chorister'
            placement='right'
            handleClick={ confirmChoristerDelete }
          />
        )}

        <img
          src={
            chorister.photo ||
            'https://www.property118.com/wp-content/uploads/2013/06/How-to-I-add-my-picture-to-comments-1024x819.jpg'
          }
          alt={ fullName }
        />

        <p className='chorister-full-name'>{ fullName }</p>

        {chorister.is_senior && (
          <div className='chorister-senior-label'>
            Senior
          </div>
        )}

        {(chorister.first_year && chorister.last_year) &&
          <p className='chorister-years'>
            {chorister.first_year}-{chorister.last_year}
          </p>
        }
      </div>
    </div>
  );
}

export default Chorister;
