import { useEffect, useRef, useState } from 'react';

import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  getEventsByMonths,
  eventsInfo
} from '../../../../redux/features/events/eventsSlice';

import Loading from '../../../generic/Loading';

import {
  convertDateTimeToDayWithOrdinal,
  convertDateTimeToTime
} from '../../../../utilities';

import './HomeUpcomingEvents.css';

function HomeUpcomingEvents() {

  const eventsTitleRef = useRef();

  const [isEventsTitleVisible, setIsEventsTitleVisible] = useState(false);

  const dispatch = useDispatch();
  const { isLoading, eventsByMonths } = useSelector(eventsInfo);

  useEffect(() => {
    if (!eventsByMonths.length) {
      dispatch(getEventsByMonths());
    }
  }, [dispatch, eventsByMonths.length]);

  useEffect(() => {
    if (!isLoading) {
      const handleObserver = entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsEventsTitleVisible(true);
            elementsObserver.unobserve(eventsTitleRef.current);
          }
        });
      };
      const observeOptions = { threshold: 1.00 };
      const elementsObserver = new IntersectionObserver(
        handleObserver,
        observeOptions
      );
      elementsObserver.observe(eventsTitleRef.current);

      return () => elementsObserver.disconnect();
    }
  }, [isLoading]);

  const nextTwoEvents = [];
  if (nextTwoEvents.length !== 2) {
    nextTwoEvents.length = 0; // empty array
    for (const eventMonth of eventsByMonths) {
      if (nextTwoEvents.length >= 2) break;
  
      for (const event of eventMonth.events) {
        nextTwoEvents.push({
          ...event,
          month: eventMonth.monthYear.split(' ')[0]
        });
        if (nextTwoEvents.length >= 2) break;
      };
    };
  }

  const displayNextTwoEvents = () => {
    return nextTwoEvents.map(event => {
      const startTime = convertDateTimeToTime(event.start_date_time);
      const dayWithOrdinal = convertDateTimeToDayWithOrdinal(event.start_date_time);

      return (
        <div
          key={ event.id }
          className={`next-event${isEventsTitleVisible ? ' is-visible' : ''}`}
        >
          <p className='next-event-title'>{ event.title }</p>
          <span className='next-event-date'>{ event.month } { dayWithOrdinal }</span>
          <span className='next-event-date-separator'>|</span>
          <span className='next-event-start-time'>{ startTime }</span>
        </div>
      );
    });
  };

  return (
    <div id='events' className='home-upcoming-events'>
      {isLoading
        ? <Loading />
        : <>
          <h3
            ref={ eventsTitleRef }
            className={`home-upcoming-events-title${isEventsTitleVisible ? ' is-visible' : ''}`}
          >
            Watch us live!
          </h3>
          <div className='next-upcoming-events'>
            { displayNextTwoEvents() }
          </div>
          <Link
            to='events'
            role='events link'
            className={`events-link${isEventsTitleVisible ? ' is-visible' : ''}`}
          >
            <Button
              className='go-to-events-button'
              variant='outline-warning'
              role='link'
            >
              See more events
            </Button>
          </Link>
        </>
      }
    </div>
  )
}

export default HomeUpcomingEvents;
