import './Loading.css';

// const loadingAnimation = 'https://assets.materialup.com/uploads/97ccd18f-7023-49f9-bc14-f2c9a8067db8/preview.gif';
import loadingAnimation from '../../../assets/images/loading-animation.gif';

function Loading({ isPreview }) {

  const previewClass = isPreview ? ' preview' : '';

  return (
    <div className={ `loading-container${previewClass}` }>
      <div className={ `loading-image-wrapper${previewClass}` }>
        <img src={ loadingAnimation } alt='loading animation' />
      </div>
    </div>
  );
}

export default Loading;