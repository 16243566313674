import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiUrl } from '../../../utilities';

export const getChoirs = createAsyncThunk(
  'app/getChoirs',
  async () => {
    try {
      const response = await fetch(`${apiUrl}/choirs`);
      const choirs = await response.json();
      return { isLoading: false, choirs };
    } catch(error) {
      console.error(`Error retrieving choirs: ${error.message}`);
    }
  }
);

export const choirsSlice = createSlice({
  name: 'choirs',
  initialState: {
    isLoading: true,
    choirs: []
  },
  reducers: {
    addChorister(state, action) {
      const newChorister = action.payload;
      state.choirs = state.choirs.map(choir => {
        return choir.id === newChorister.choir_id
          ? { ...choir, choristers: [...choir.choristers, newChorister] }
          : choir;
      });
    },
    removeChorister(state, action) {
      const removedChorister = action.payload;
      state.choirs = state.choirs.map(choir => {
        return choir.id === removedChorister.choir_id
          ? {
            ...choir,
            choristers: choir.choristers.filter(chorister => {
              return chorister.id !== removedChorister.id;
            })
          }
          : choir;
      });
    },
    updateChoirAttribute(state, action) {
      const { choirId, attribute, newValue } = action.payload;
      state.choirs = state.choirs.map(choir => {
        return choir.id === choirId
          ? { ...choir, [attribute]: newValue }
          : choir;
      });
    }
  },
  extraReducers: builder => {
    builder.addCase(
      getChoirs.fulfilled,
      (_, action) => action.payload
    );
  }
});

export const choirsInfo = state => state.choirs;

export const {
  addChorister,
  removeChorister,
  updateChoirAttribute
} = choirsSlice.actions;

export default choirsSlice.reducer;
