import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';

import { displayModal } from '../../../../redux/features/modal/modalSlice';
import { updateCartItemQuantity } from '../../../../redux/features/cart/cartSlice';

import DeleteButtonWithTooltip from '../../../generic/DeleteButtonWithTooltip';

import './CheckoutItem.css';

function CheckoutItem({ cartItem }) {

  const dispatch = useDispatch();

  const imageName = `${cartItem.group} ${cartItem.category}`;

  const confirmRemoveFromCart = () => {
    dispatch(displayModal({
      innerComponentName: 'Confirm',
      backgroundInfo: {
        handleConfirmName: 'handleRemoveItemFromCart',
        isDelete: true,
        message: `Remove ${cartItem.group} ${cartItem.category},`
          + ` ${cartItem.quantity} ${cartItem.size}, from bag?`,
        details: {
          cartItem
        },
        displayImage: {
          src: cartItem.image,
          alt: imageName,
        }
      }
    }));
  };

  const updateQuantity = newQuantity => {
    dispatch(updateCartItemQuantity({
      cartItemId: cartItem.id,
      newQuantity
    }));
  }

  const decreaseQuantity = () => {
    if (cartItem.quantity === 1) {
      confirmRemoveFromCart();
    } else if (cartItem.quantity > 1) {
      updateQuantity(cartItem.quantity - 1);
    }
  };

  const increaseQuantity = () => {
    updateQuantity(cartItem.quantity + 1);
  };

  const isQuantityOne = cartItem.quantity === 1;

  return (
    <div className='cart-swag-item' key={ cartItem.id }>
      <div className='cart-swag-image-container'>
        <img
          className='cart-swag-image'
          src={ cartItem.image }
          alt={ imageName }
        />
      </div>

      <div className='cart-item-details'>
        <h5 className='cart-swag-group'>
          { cartItem.group }{' '}
          <p className='cart-swag-category'>{ cartItem.category }</p>
        </h5>

        <p className='cart-swag-size'>Size: { cartItem.size }</p>

        <div className='cart-swag-quantity-container'>
          <ButtonToolbar aria-label="Swag quantity with add and subtract buttons">
            <ButtonGroup size='sm' aria-label="add or subtract item quantity">
              <Button
                className={`cart-quantity-subtract${isQuantityOne ? ' is-one' : ''}`}
                variant='outline-primary'
                onClick={ decreaseQuantity }
              >
                <FontAwesomeIcon icon={ isQuantityOne ? faTrashAlt : faMinus } />
              </Button>

              <Button
                className='cart-item-quantity'
                variant='outline-primary'
                disabled
              >
                { cartItem.quantity }
              </Button>

              <Button
                className='cart-quantity-add'
                variant='outline-primary'
                onClick={ increaseQuantity }
              >
                <FontAwesomeIcon icon={ faPlus } />
              </Button>
            </ButtonGroup>

            {!isQuantityOne && (
              <DeleteButtonWithTooltip
                itemName='checkout-item'
                placement='right'
                handleClick={ confirmRemoveFromCart }
              />
            )}
          </ButtonToolbar>
        </div>
      </div>
    </div>
  );
}

export default CheckoutItem;
