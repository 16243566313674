import { createSlice } from '@reduxjs/toolkit';

export const currentUrlSlice = createSlice({
  name: 'modal',
  initialState: '',
  reducers: {
    setCurrentUrlPath(_, action) {
      return action.payload;
    }
  }
});

export const currentUrlPathString = state => state.currentUrlPath;

export const { setCurrentUrlPath } = currentUrlSlice.actions;

export default currentUrlSlice.reducer;
