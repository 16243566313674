import Button from 'react-bootstrap/Button';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useSelector } from 'react-redux';

import { authenticated } from '../../../redux/features/authenticated/authenticatedSlice';

import './EditButtonWithTooltip.css';

function EditButtonWithTooltip({
  isAdminOnly,
  itemName,
  tooltipText,
  placement,
  handleClick
}) {

  const isAuthenticated = useSelector(authenticated);

  const renderEditTooltip = props => (
    <Tooltip id={ `${itemName}-edit-button-tooltip` } {...props}>
      { tooltipText || 'Edit' }
    </Tooltip>
  );

  return (
    <>
      {!isAdminOnly || isAuthenticated
        ? (
          <OverlayTrigger
          placement={ placement }
          delay={{ show: 50, hide: 150 }}
          overlay={ renderEditTooltip }
          >
            <Button
              className={ `${itemName}-edit-button` }
              variant='outline-warning'
              onClick={ handleClick }
            >
              <FontAwesomeIcon icon={ faPencilAlt } />
            </Button>
          </OverlayTrigger>
        )
        : null
      }
    </>
  );
}

export default EditButtonWithTooltip;
