import { useEffect, useRef, useState } from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { authenticated } from '../../../../redux/features/authenticated/authenticatedSlice';
import {
  getHomeVideo,
  homeVideoInfo,
  updateHomeVideo
} from '../../../../redux/features/homepage/homeVideoSlice';
import {
  getVideos,
  videosInfo
} from '../../../../redux/features/videos/videosSlice';
import { showAppAlert } from '../../../../redux/features/appAlert/appAlertSlice';

import Loading from '../../../generic/Loading';
import VideoPlayer from '../../Media/VideoPlayer';

import { apiUrl, authFetch } from '../../../../utilities';

import './HomeVideos.css';

function HomeVideos() {

  const dispatch = useDispatch();
  const { homeVideo } = useSelector(homeVideoInfo);
  const { isLoading: isVideosLoading, videos } = useSelector(videosInfo);
  const isAuthenticated = useSelector(authenticated);

  const containerRef = useRef();

  const [isContainerVisible, setIsContainerVisible] = useState(false);
  const [videoPlayingId, setVideoPlayingId] = useState(0);

  const choirVideo = videos.find(video => video.id === homeVideo.video_id);

  useEffect(() => {
    if (!homeVideo.video_id) {
      dispatch(getHomeVideo());
    }
  }, [dispatch, homeVideo.video_id]);

  useEffect(() => {
    if (!videos.length) {
      dispatch(getVideos());
    }
  }, [dispatch, videos.length]);

  useEffect(() => {
    if (!isVideosLoading) {
      const handleObserver = entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsContainerVisible(true);
            elementsObserver.unobserve(containerRef.current);
          }
        });
      };
      const observeOptions = { threshold: 0.50 };
      const elementsObserver = new IntersectionObserver(
        handleObserver,
        observeOptions
      );
      elementsObserver.observe(containerRef.current);

      return () => elementsObserver.disconnect();
    }
  }, [isVideosLoading]);

  const handleUpdatedHomeVideo = updatedHomeVideo => {
    if (updatedHomeVideo.id) {
      dispatch(updateHomeVideo({ updatedHomeVideo }));

      dispatch(showAppAlert({
        title: 'Success',
        message: `Homepage video updated!`,
        bg: 'success'
      }));
    } else {
      const errorMessage = updateHomeVideo.errors
        .reduce((errorString, error, index) => {
          return errorString += `${index + 1}. ${error}\n`;
        }, '');
      throw new Error(errorMessage);
    }
  };

  const updateVideo = async event => {
    const newVideoId = parseInt(event.target.value, 10);
    console.log('newVideoId', newVideoId);

    try {
      const updatedHomeVideo = await authFetch(
        `${apiUrl}/home_videos/${homeVideo.id}`,
        'PATCH',
        { home_video: { video_id: newVideoId } }
      );

      handleUpdatedHomeVideo(updatedHomeVideo);

    } catch (error) {
      dispatch(showAppAlert({ message: error.message }));
    }
  };

  const renderVideoOptions = () => {
    return videos.map(video => {
      return (
        <option key={ video.id } value={ video.id }>
          { video.title } - { video.name }, { video.year }
        </option>
      );
    });
  };

  return (
    <div id='video' ref={ containerRef } className='home-videos'>
      { isVideosLoading && <Loading /> }

      {choirVideo && (
        <VideoPlayer
          key={ choirVideo.id }
          customClass={isContainerVisible ? ' is-visible' : ''}
          video={ choirVideo }
          videoPlayingId={ videoPlayingId }
          setVideoPlayingId={ setVideoPlayingId }
          isHomePage
        />
      )}

      {isAuthenticated && choirVideo && (
        <Form.Group
          className="home-page-video-select-form"
          controlId='formHomePageVideoSelect'
        >
          <Form.Label className='home-page-video-select-label'>
            Choose a different video
          </Form.Label>
          <Form.Select
            aria-label='homepage video select'
            onChange={ updateVideo }
            value={ choirVideo.id }
          >
            <option>Choose different video</option>
            { renderVideoOptions() }
          </Form.Select>
        </Form.Group>
      )}

      <Link
        to='media'
        role='media link'
        className={`media-link${isContainerVisible ? ' is-visible' : ''}`}
      >
        <Button
          className='go-to-performances-button'
          variant='outline-warning'
          role='link'
        >
          Watch more performances
        </Button>
      </Link>
    </div>
  );
}

export default HomeVideos;
