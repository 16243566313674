import { removeChoirVibesPhoto } from "../../../../../redux/features/choirVibes/choirVibesPhotosSlice";
import { showAppAlert } from '../../../../../redux/features/appAlert/appAlertSlice';

import { apiUrl } from '../../../../../utilities';

import {
  storage,
  ref,
  deleteObject
} from "../../../../../firebase/firebase";

export const handleChoirVibesPhotoDelete = async (closeModal, dispatch, details) => {
  const { choirVibesPhoto } = details;

  const firebaseStorageUrl = `/media/choir_vibes/carousel/${choirVibesPhoto.description}`;
  const firebaseImageRef = ref(storage, firebaseStorageUrl);

  const choirVibesPhotoUrl = `${apiUrl}/choir_vibes_photos/${choirVibesPhoto.id}`;
  const deleteOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${localStorage.token}`
    }
  };

  try {
    await deleteObject(firebaseImageRef);
    const response = await fetch(choirVibesPhotoUrl, deleteOptions);
    const { order_updates: orderUpdates } = await response.json();

    if (response.ok) {
      dispatch(showAppAlert({
        title: 'Success',
        message: `${choirVibesPhoto.description} removed`,
        bg: 'success'
      }));
      dispatch(removeChoirVibesPhoto({ removedChoirVibesPhoto: choirVibesPhoto, orderUpdates }));
      closeModal();

    } else {
        const { error, exception } = await response.json();
        const errorMessage =
          `Error removing ${choirVibesPhoto.description}: ${exception || error}`;
        dispatch(showAppAlert({ message: errorMessage }));
    }

  } catch (error) {
    const errorMessage = `Error removing ${choirVibesPhoto.description}: ${error.message}`;
    dispatch(showAppAlert({ message: errorMessage }));
  }
};
