import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: [],
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addCartItem(state, { payload }) {
      const newCartItem = payload;

      const cartIndex = state.items.findIndex(cartItem => {
        return cartItem.id === newCartItem.id && cartItem.size === newCartItem.size;
      });
      if (cartIndex !== -1) {
        const existingItem = state.items[cartIndex];
        state.items[cartIndex] = {
          ...existingItem,
          quantity: existingItem.quantity + newCartItem.quantity
        };
        return;
      }

      state.items = [...state.items, newCartItem];
    },
    removeCartItem(state, { payload: { cartItemId, cartItemIndex } }) {
      const cartItemsWithOneRemoved = state.items.filter((cartItem, index) => {
        return !(cartItem.id === cartItemId && index === cartItemIndex);
      });
      state.items = cartItemsWithOneRemoved;
    },
    updateCartItemQuantity(state, { payload: { cartItemId, newQuantity } }) {
      if (newQuantity < 1) return;

      const cartIndex = state.items.findIndex(cartItem => {
        return cartItem.id === cartItemId
      });
      state.items[cartIndex] = {
        ...state.items[cartIndex],
        quantity: newQuantity
      };
    },
  }
});

export const cartItemsFromStore = state => state.cart.items;
export const cartNumberOfItemsStore = state => {
  return state.cart.items.reduce((total, cartItem) => {
    return total + cartItem.quantity; 
  }, 0);
};

export const {
  addCartItem,
  removeCartItem,
  updateCartItemQuantity
} = cartSlice.actions;

export default cartSlice.reducer;
