import { configureStore } from '@reduxjs/toolkit';

import appAlertReducer from './features/appAlert/appAlertSlice';
import audiosReducer from  './features/audios/audiosSlice';
import authenticatedReducer from  './features/authenticated/authenticatedSlice';
import cartReducer from  './features/cart/cartSlice';
import choirVibesPhotosReducer from  './features/choirVibes/choirVibesPhotosSlice';
import choirsReducer from  './features/choirs/choirsSlice';
import currentUrlPathReducer from './features/currentUrlPath/currentUrlPathSlice';
import eventsReducer from  './features/events/eventsSlice';
import googleCalendarKeysReducer from  './features/googleCalendar/googleCalendarKeysSlice';
import homeCarouselPhotosReducer from  './features/homepage/homeCarouselPhotosSlice';
import homeGalleryPhotosReducer from  './features/homepage/homeGalleryPhotosSlice';
import homeVideoReducer from  './features/homepage/homeVideoSlice';
import loginReducer from  './features/login/loginSlice';
import modalReducer from './features/modal/modalSlice';
import staffReducer from './features/staff/staffSlice';
import swagsReducer from './features/swags/swagsSlice';
import videosReducer from  './features/videos/videosSlice';

export default configureStore({
  reducer: {
    appAlert: appAlertReducer,
    audios: audiosReducer,
    authenticated: authenticatedReducer,
    cart: cartReducer,
    choirVibesPhotos: choirVibesPhotosReducer,
    choirs: choirsReducer,
    currentUrlPath: currentUrlPathReducer,
    events: eventsReducer,
    googleCalendarKeys: googleCalendarKeysReducer,
    homeCarouselPhotos: homeCarouselPhotosReducer,
    homeGalleryPhotos: homeGalleryPhotosReducer,
    homeVideo: homeVideoReducer,
    login: loginReducer,
    modal: modalReducer,
    staff: staffReducer,
    swags: swagsReducer,
    videos: videosReducer,
  }
});
