import Button from 'react-bootstrap/Button';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';

import { authenticated } from '../../../../../redux/features/authenticated/authenticatedSlice';
import { displayModal } from '../../../../../redux/features/modal/modalSlice';

import DeleteButtonWithTooltip from '../../../../generic/DeleteButtonWithTooltip';
import EditButtonWithTooltip from '../../../../generic/EditButtonWithTooltip';

import './ChoirVibesPhoto.css';

function ChoirVibesPhoto({ choirVibesPhoto }) {

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(authenticated);

  const showAddOrEditChoirVibesPhotoModal = isAdd => {
    const backgroundInfo = isAdd ? {} : { backgroundInfo: { choirVibesPhoto } };

    dispatch(displayModal({
      innerComponentName: 'AddOrEditChoirVibesPhotoForm',
      ...backgroundInfo
    }));
  };

  const confirmChoirVibesPhotoDelete = () => {
    dispatch(displayModal({
      innerComponentName: 'Confirm',
      backgroundInfo: {
        handleConfirmName: 'handleChoirVibesPhotoDelete',
        isDelete: true,
        message: `Remove photo #${choirVibesPhoto.order} "${choirVibesPhoto.description}" permanently?`,
        details: {
          choirVibesPhoto
        },
        displayImage: {
          src: choirVibesPhoto.photo,
          alt: choirVibesPhoto.description,
        }
      }
    }));
  };

  return (
    <>
      <img
        className='d-block w-100 choir-vibes-photo-image'
        src={ choirVibesPhoto.photo }
        alt={ choirVibesPhoto.description }
      />

      <p className='choir-vibes-photo-description'>
        { choirVibesPhoto.description }
      </p>

      {isAuthenticated && (
        <div className='choir-vibes-photo-action-buttons'>
          <Button
            className='choir-vibes-photo-add-button'
            variant='outline-primary'
            onClick={ () => showAddOrEditChoirVibesPhotoModal(true) }
          >
            <FontAwesomeIcon icon={ faPlus } />
          </Button>

          <EditButtonWithTooltip
            itemName='choir-vibes-photo'
            tooltipText='Update photo'
            placement='top'
            handleClick={ () => showAddOrEditChoirVibesPhotoModal(false) }
          />

          <DeleteButtonWithTooltip
            itemName='choir-vibes-photo'
            tooltipText='Remove photo'
            placement='top'
            handleClick={ confirmChoirVibesPhotoDelete }
          />
        </div>
      )}
    </>
  );
}

export default ChoirVibesPhoto;
