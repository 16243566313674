import { useState } from 'react';

import ReactPlayer from 'react-player/lazy';
import { useDispatch, useSelector } from 'react-redux';

import { authenticated } from '../../../../redux/features/authenticated/authenticatedSlice';
import { displayModal } from '../../../../redux/features/modal/modalSlice';

import DeleteButtonWithTooltip from '../../../generic/DeleteButtonWithTooltip';
import Loading from '../../../generic/Loading';

import './AudioPlayer.css';

function AudioPlayer({
  audio,
  audioPlayingId,
  setAudioPlayingId,
  isPreview
}) {

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(authenticated);

  const [isLoading, setIsLoading] = useState(true);

  const confirmAudioDelete = () => {
    dispatch(displayModal({
      innerComponentName: 'Confirm',
      backgroundInfo: {
        handleConfirmName: 'handleAudioDelete',
        isDelete: true,
        message: `Remove ${audio.title}, ${audio.name} permanently?`,
        details: {
          audio
        }
      }
    }));
  };

  let nonPreviewProps = {};
  if (!isPreview) {
    nonPreviewProps = {
      playing: audio.id === audioPlayingId,
      onPlay: () => setAudioPlayingId(audio.id),
      onPause: () => audioPlayingId === audio.id && setAudioPlayingId(0)
    }
  }

  return (
    <div key={ audio.id } className='audio-container'>
      <div className='audio-title-and-delete-container'>
        <h3 className='audio-title'>{ audio.title || 'Title' }</h3>

        {isAuthenticated && !isPreview && (
          <DeleteButtonWithTooltip
            itemName='audio'
            placement='right'
            handleClick={ confirmAudioDelete }
          />
        )}
      </div>

      <div className='audio-details-container'>
        <span className='audio-sub-title'>{ audio.name || 'Name' }</span>

        {(!!audio.year || isPreview) && (
          <span className='audio-year'>
            { isPreview ? (audio.year || 'year') : audio.year }
          </span>
        )}
      </div>

      { (isLoading || !audio.url) && <Loading isPreview /> }
      <ReactPlayer
        url={ audio.url }
        className={
          `audio-player-container${isPreview ? ' preview' : ''}`
        }
        style={{
          maxWidth: '100%',
          maxHeight: 60,
          display: (isLoading || !audio.url) ? 'none' : 'block'
        }}
        onReady={ () => setIsLoading(false) }
        { ...nonPreviewProps }
        controls
      />
    </div>
  );
}

export default AudioPlayer;
