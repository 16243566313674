import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiUrl } from '../../../utilities';

export const getAudios = createAsyncThunk(
  'app/getAudios',
  async () => {
    try {
      const response = await fetch(`${apiUrl}/audios`);
      const audios = await response.json();
      return { isLoading: false, audios };
    } catch(error) {
      console.error(`Error retrieving audio: ${error.message}`);
    }
  }
);

const sortByYearDesc = (a, b) => b.year - a.year;

export const audiosSlice = createSlice({
  name: 'audios',
  initialState: {
    isLoading: true,
    audios: []
  },
  reducers: {
    addAudio(state, action) {
      const { newAudio } = action.payload;
      state.audios = [newAudio, ...state.audios]
        .sort(sortByYearDesc);
    },
    removeAudio(state, action) {
      const { removedAudio } = action.payload;
      state.audios = state.audios.filter(audio => {
        return audio.id !== removedAudio.id;
      });
    }
  },
  extraReducers: builder => {
    builder.addCase(
      getAudios.fulfilled,
      (_, action) => action.payload
    );
  }
});

export const audiosInfo = state => state.audios;

export const { addAudio, removeAudio } = audiosSlice.actions;

export default audiosSlice.reducer;
