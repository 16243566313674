import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiUrl } from '../../../utilities';

export const getStaff = createAsyncThunk(
  'app/getStaff',
  async () => {
    try {
      const response = await fetch(`${apiUrl}/staffs`);
      const staffMembers = await response.json();
      return { isLoading: false, staffMembers };
    } catch(error) {
      console.error(`Error retrieving staff: ${error.message}`);
    }
  }
);

export const staffSlice = createSlice({
  name: 'staff',
  initialState: {
    isLoading: true,
    staffMembers: []
  },
  reducers: {
    updateStaffMember(state, action) {
      const updatedStaffMember = action.payload;
      state.staffMembers = state.staffMembers.map(staff => {
        return staff.id === updatedStaffMember.id
          ? { ...updatedStaffMember }
          : staff;
      });
    }
  },
  extraReducers: builder => {
    builder.addCase(
      getStaff.fulfilled,
      (_, action) => action.payload
    );
  }
});

export const staffInfo = state => state.staff;

export const { updateStaffMember } = staffSlice.actions;

export default staffSlice.reducer;
