import { useEffect, useState } from 'react';

import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { useDispatch, useSelector } from 'react-redux';

import { audiosInfo, getAudios } from '../../../redux/features/audios/audiosSlice';

import SiteAudioPlaylist, { PlayerInterface, Track } from './SiteAudioPlaylist/index';

import makeTheme from './SiteAudioPlaylist/makeTheme';

function SiteAudioPlaylistContainer() {
  const theme = makeTheme('dark');

  const [isTracksSet, setIsTracksSet] = useState(false);

  const dispatch = useDispatch();
  const { isLoading, audios } = useSelector(audiosInfo);

  useEffect(() => {
    if (!audios.length) {
      dispatch(getAudios());
    }
  }, [dispatch, audios.length]);

  if (!isLoading && !isTracksSet) {
    const tracks = audios.map(audio => {
      return new Track( 
        `${audio.id}`,
        'https://northfieldchoir.org/static/media/logo-2021.df25015244bcb9acbad6.jpg',
        audio.title,
        audio.event_type, 
        audio.url
      );
    });
    PlayerInterface.play(tracks);

    setIsTracksSet(true);
  }

  return (
    <ThemeProvider theme={theme}>
      <SiteAudioPlaylist
        sx={{
          boxShadow: '8',
          borderRadius: '1px 1px 0 0',
          height: '4rem',
          zIndex: '10'
        }}
      />
    </ThemeProvider>
  );
}

export default SiteAudioPlaylistContainer;
