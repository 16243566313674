import { useState } from 'react';

import Button from 'react-bootstrap/Button';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';

import { addVideo } from '../../../../redux/features/videos/videosSlice';
import { modalBackgroundInfo } from '../../../../redux/features/modal/modalSlice';
import { showAppAlert } from '../../../../redux/features/appAlert/appAlertSlice';

import VideoPlayer from '../VideoPlayer';

import { apiUrl, isValidYear } from '../../../../utilities';

import './AddMediaForm.css';

function AddMediaForm({ closeModal }) {

  const dispatch = useDispatch();
  const { videoEventType } = useSelector(modalBackgroundInfo);

  const [busy, setBusy] = useState(false);
  const [videoPlayingId, setVideoPlayingId] = useState(0);

  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [year, setYear] = useState('');
  const [url, setUrl] = useState('');

  const video = { title, name, year, url, event_type: videoEventType };

  const makeNewVideoRequestOptions = () => ({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ video })
  });

  const saveFailed = errorMessage => {
    dispatch(showAppAlert({ message: errorMessage }));
    setBusy(false);
  };

  const handleSaveNewVideo = async event => {
    event.preventDefault();

    setBusy(true);

    try {
      const createOptions = makeNewVideoRequestOptions();
      const response = await fetch(`${apiUrl}/videos`, createOptions);
      const newVideo = await response.json();

      if (newVideo.id) {
        dispatch(addVideo(newVideo));
        dispatch(showAppAlert({
          title: 'Success',
          message: `${newVideo.title}, ${newVideo.name} added`,
          bg: 'success'
        }));
        closeModal();
      } else {
        const errorMessage = newVideo.errors
          .reduce((errorString, error, index) => {
            return errorString += `${index + 1}. ${error}\n`;
          }, '');
        saveFailed(errorMessage);
      }

    } catch (error) {
      saveFailed(error.message);
    }
  };

  const isValidYearVariable = isValidYear(year);
  const isYouTubeWatchUrl = /youtube.com\/watch/.test(url);
  const isAnyFieldInvalid =
    !title ||
    !name ||
    !isValidYearVariable ||
    !isYouTubeWatchUrl;

  const eventTypes = {
    events: 'DPS Events',
    concerts: 'Northfield HS Concerts',
    graduation: 'Graduation'
  };

  return (
    <section className='add-media-form-container'>
      <h2 className='video-event-type'>{ eventTypes[videoEventType] }</h2>

      <VideoPlayer
        video={ { ...video, id: url } }
        videoPlayingId={ videoPlayingId }
        setVideoPlayingId={ setVideoPlayingId }
        isPreview
      />

      <Form className='add-media-form'>
        <Form.Group className='mb-3' controlId='formTitle'>
          <Form.Label>Title</Form.Label>
          <Form.Control
            isInvalid={ !title }
            isValid={ title }
            type='text'
            placeholder='Title'
            value={ title }
            onChange={ event => setTitle(event.target.value) }
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formName'>
          <Form.Label>Name</Form.Label>
          <Form.Control
            isInvalid={ !name }
            isValid={ name }
            type='text'
            placeholder='Name'
            value={ name }
            onChange={ event => setName(event.target.value) }
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formYear'>
          <Form.Label>Year</Form.Label>
          <Form.Control
            isInvalid={ !isValidYearVariable }
            isValid={ isValidYearVariable }
            type='text'
            placeholder='Year'
            value={ year }
            onChange={ event => setYear(event.target.value) }
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formYouTubeUrl'>
          <Form.Label>YouTube URL</Form.Label>
          <Form.Control
            isInvalid={ !isYouTubeWatchUrl }
            isValid={ isYouTubeWatchUrl }
            type='text'
            placeholder='YouTube url'
            value={ url }
            onChange={ event => setUrl(event.target.value) }
          />
        </Form.Group>

        <Button
          disabled={ isAnyFieldInvalid || busy }
          variant='primary'
          type='submit'
          onClick={ handleSaveNewVideo }
        >
          Save <FontAwesomeIcon icon={ faSave } />
        </Button>
      </Form>
    </section>
  );
}

export default AddMediaForm;
