import { initializeApp } from "firebase/app";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject
} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDBAN8rkLPEqG29yqViIzv5YI0vPcXVtGI",
  authDomain: "northfield-choir.firebaseapp.com",
  projectId: "northfield-choir",
  storageBucket: "northfield-choir.appspot.com",
  messagingSenderId: "1021001674364",
  appId: "1:1021001674364:web:e064a689d63299697623f9"
};

const firebaseApp = initializeApp(firebaseConfig);

const storage = getStorage(firebaseApp);

export {
  storage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
  firebaseApp as default
};
