import { useState } from 'react';

import { useOutletContext, useParams } from 'react-router-dom';

import AudioPlayer from '../../AudioPlayer';

import './AudiosContainer.css';

function AudiosContainer() {

  const { audios } = useOutletContext();

  const { eventType } = useParams();

  const [audioPlayingId, setAudioPlayingId] = useState(0);

  const displayEventSpecificAudios = () => {
    return audios
      .filter(audio => audio.event_type === eventType)
      .map(audio => {
        return (
          <AudioPlayer
            key={ audio.id }
            audio={ audio }
            audioPlayingId={ audioPlayingId }
            setAudioPlayingId={ setAudioPlayingId }
          />
        );
      });
  };

  return (
    <div className='audios-container'>
      { displayEventSpecificAudios() }
    </div>
  );
}

export default AudiosContainer;
