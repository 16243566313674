import { useEffect } from 'react';

import { Outlet, useNavigate, useParams } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from 'react-redux';

import { getStaff, staffInfo } from '../../../redux/features/staff/staffSlice';

import Loading from '../../generic/Loading';

import './Staff.css';

function Staff() {

  const { staffMemberNameDashed: selectedStaffMember } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { isLoading, staffMembers } = useSelector(staffInfo);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (!staffMembers.length) {
      dispatch(getStaff());
    }
  }, [dispatch, staffMembers.length]);

  const displayStaffMembers = () => {
    return staffMembers.map(staffMember => {
      return (
        <Tab
          key={ staffMember.id }
          eventKey={ staffMember.title.toLowerCase().replace(' ', '-') }
          title={ staffMember.title }
        >
          <Outlet context={ { staffMember } } />
        </Tab>
      );
    })
  };

  const handleStaffMemberSelect = eventKey => {
    navigate(eventKey);
  };

  return (
    <>
      {isLoading
        ? <Loading />
        : (
          <section className='staff'>
            <h2 className='staff-heading'>Northfield Choir Support Staff</h2>
            <Tabs
              defaultActiveKey='director'
              id='staff-tabs'
              className='mb-3'
              activeKey={ selectedStaffMember }
              onSelect={ handleStaffMemberSelect }
            >
              { displayStaffMembers() }
            </Tabs>
          </section>
        )
      }
    </>
  );
}

export default Staff;
