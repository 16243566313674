import { useEffect, useRef } from 'react';

import FullCalendar from '@fullcalendar/react'
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'

import { useDispatch, useSelector } from 'react-redux';
import { googleCalendarKeys, getGoogleCalendarKeys } from '../../../../redux/features/googleCalendar/googleCalendarKeysSlice';

import Loading from '../../../generic/Loading';

import './Calendar.css';

function Calendar() {

  const calendarRef = useRef(null);

  const {
    isLoading,
    googleCalendarApiKey,
    googleCalendarId
  } = useSelector(googleCalendarKeys);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!googleCalendarId && !googleCalendarApiKey) {
      dispatch(getGoogleCalendarKeys());
    }
  }, [dispatch, googleCalendarApiKey, googleCalendarId]);

  const goToDayView = day => {
    calendarRef.current.getApi().changeView('timeGridDay', day);
  }

  const handleCalendarEventClick = info => {
    info.jsEvent.preventDefault(); // stop link from firing
    goToDayView(info.event.start);
  }

  return (
    <>
      {isLoading
        ? <Loading />
        : <FullCalendar
          ref={ calendarRef }
          height='75vh'
          plugins={[
            dayGridPlugin,
            googleCalendarPlugin,
            interactionPlugin,
            listPlugin,
            timeGridPlugin
          ]}
          initialView='dayGridMonth'
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
          }}
          events={{ googleCalendarId }}
          googleCalendarApiKey={ googleCalendarApiKey }
          eventClick={ handleCalendarEventClick }
          navLinks
        />
      }
    </>
  );
}

export default Calendar;
