import { useState } from 'react';

import { useOutletContext, useParams } from 'react-router-dom';

import VideoPlayer from '../../../VideoPlayer';

import './VideosContainer.css';

function VideosContainer() {

  const { videos } = useOutletContext();

  const { eventType } = useParams();

  const [videoPlayingId, setVideoPlayingId] = useState(0);

  const displayEventSpecificVideos = () => {
    return videos
      .filter(video => video.event_type === eventType)
      .map(video => {
        return (
          <VideoPlayer
            key={ video.id }
            video={ video }
            videoPlayingId={ videoPlayingId }
            setVideoPlayingId={ setVideoPlayingId }
          />
        );
      });
  };

  return (
    <div className='videos-container'>
      { displayEventSpecificVideos() }
    </div>
  );
}

export default VideosContainer;
