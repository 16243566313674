import Button from 'react-bootstrap/Button';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Nav from 'react-bootstrap/Nav';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { authenticated } from '../../../../../redux/features/authenticated/authenticatedSlice';
import { displayModal } from '../../../../../redux/features/modal/modalSlice';

import VideoNavPill from './VideoNavPill';

import './VideosTab.css';

const videoEventTypes = [
  { eventType: 'events',  title: 'DPS Events' },
  { eventType: 'concerts', title: 'Northfield HS Concerts' },
  { eventType: 'graduation', title: 'Graduation' }
];

function VideosTab({ videos }) {

  const { eventType } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(authenticated);

  const showAddMediaModal = () => {
    const innerComponentName = 'AddMediaForm';
    const backgroundInfo = { eventType };
    dispatch(displayModal({ innerComponentName, backgroundInfo }));
  };

  const displayNavPills = () => {
    return videoEventTypes.map(videoEventType => {
      return <VideoNavPill
        key={ videoEventType.eventType }
        videoEventType={ videoEventType }
      />;
    });
  };

  const handlePillSelect = eventKey => {
    navigate(eventKey);
  };

  return (
    <div className='media-tab-videos'>
      <div className='media-pills-and-add-media-button-container'>
        <Nav
          variant='pills'
          role='video-type-selector'
          defaultActiveKey='events'
          activeKey={ eventType }
          onSelect={ handlePillSelect }
        >
          { displayNavPills() }
        </Nav>

        {isAuthenticated && (
          <Button
            className='add-video'
            variant='outline-primary'
            onClick={ showAddMediaModal }
          >
            <FontAwesomeIcon icon={ faPlus } />
          </Button>
        )}
      </div>

      <Outlet context={ { videos } } />

    </div>
  );
}

export default VideosTab;
