import AddAudioForm from '../pages/Media/AddAudioForm';
import AddChoristerForm from '../pages/Choirs/AddChoristerForm';
import AddEventForm from '../pages/Events/AddEventForm';
import AddMediaForm from '../pages/Media/AddMediaForm';
import AddOrEditChoirVibesPhotoForm from '../pages/Media/ChoirVibes/AddOrEditChoirVibesPhotoForm';
import AddOrEditHomeCarouselPhotoForm from '../pages/Home/HomeCarousel/AddOrEditHomeCarouselPhotoForm';
import AddOrEditSwagItemForm from '../pages/SwagAndSupport/AddOrEditSwagItemForm';
import ChooseResource from '../Footer/ChooseResource';
import ChooseSyllabus from '../Footer/ChooseSyllabus';
import Confirm from '../generic/Confirm';
import EditHomeGalleryPhotoForm from '../pages/Home/HomeGallery/EditHomeGalleryPhotoForm';
import EditStaffMemberForm from '../pages/Staff/EditStaffMemberForm';
import OrderSwag from '../pages/SwagAndSupport/OrderSwag';

const modalComponents = {
  'AddAudioForm': AddAudioForm,
  'AddChoristerForm': AddChoristerForm,
  'AddEventForm': AddEventForm,
  'AddMediaForm': AddMediaForm,
  'AddOrEditSwagItemForm': AddOrEditSwagItemForm,
  'ChooseResource': ChooseResource,
  'ChooseSyllabus': ChooseSyllabus,
  'Confirm': Confirm,
  'AddOrEditChoirVibesPhotoForm': AddOrEditChoirVibesPhotoForm,
  'AddOrEditHomeCarouselPhotoForm': AddOrEditHomeCarouselPhotoForm,
  'EditHomeGalleryPhotoForm': EditHomeGalleryPhotoForm,
  'EditStaffMemberForm': EditStaffMemberForm,
  'OrderSwag': OrderSwag
};

export default modalComponents;
