import Event from '../Event';

import './EventsByMonth.css';

function EventsByMonth({ eventsByMonth, isPreview }) {

  const previewClass = isPreview ? ' preview' : '';

  const displayEvents = () => {
    return eventsByMonth.events.map(event => {
      return <Event
        key={ event.id }
        event={ event }
        isPreview={ isPreview }
      />
    })
  };

  return (
    <div className={`event-month${previewClass}`}>
      <h5 className='event-month-heading'>
        { eventsByMonth.monthYear }
      </h5>

      <ol className='event-month-list'>
        { displayEvents() }
      </ol>
    </div>
  );
}

export default EventsByMonth;
