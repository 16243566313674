import { useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import imageCompression from 'browser-image-compression';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useDispatch, useSelector } from 'react-redux';

import { addChorister } from '../../../../redux/features/choirs/choirsSlice';
import { modalBackgroundInfo } from '../../../../redux/features/modal/modalSlice';
import { showAppAlert } from '../../../../redux/features/appAlert/appAlertSlice';

import Chorister from '../Chorister';

import { apiUrl, isValidYear } from '../../../../utilities';

import {
  storage,
  ref,
  uploadBytesResumable,
  getDownloadURL
} from '../../../../firebase/firebase';

import './AddChoristerForm.css';

function AddChoristerForm({ closeModal }) {

  const dispatch = useDispatch();

  const { choir } = useSelector(modalBackgroundInfo);
  const isHonors = choir.name === 'Nighthawk Honors';
  const isAlum = choir.name === 'Nighthawk Alum';

  const [busy, setBusy] = useState(false);
  
  const [imageFile, setImageFile] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [fileProgress, setFileProgress] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);

  const [imageUrlPreview, setImageUrlPreview] = useState('');

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [isSenior, setIsSenior] = useState(false);
  const [firstYear, setFirstYear] = useState('');
  const [lastYear, setLastYear] = useState('');

  const honorsOnlyDeets = isHonors
    ? { is_senior: isSenior }
    : { };
  
  const alumOnlyDeets = isAlum
    ? { first_year: firstYear, last_year: lastYear }
    : { };

  const chorister = {
    first_name: firstName,
    last_name: lastName,
    photo: imageUrlPreview,
    choir_id: choir.id,
    ...honorsOnlyDeets,
    ...alumOnlyDeets
  };

  const clearImage = () => {
    setImageFile('');
    setImageUrlPreview('');
  };

  const startProcessing = () => {
    setBusy(true);
    setIsProcessing(true);
  };

  const resetProcessing = () => {
    setBusy(false);
    setIsProcessing(false);
    setFileProgress(0);
  };

  const handleCompressionProgress = percentComplete => {
    setFileProgress(percentComplete);
    if (percentComplete === 100) resetProcessing();
  };

  const handleImageFile = async event => {
    const file = event.target.files[0];
    if (file instanceof File && file?.type.includes('image')) {
      const compressionOptions = {
        maxSizeMB: 0.05,
        maxIteration: 50,
        onProgress: handleCompressionProgress,
        useWebWorker: true
      };
      try {
        startProcessing();
        const compressedFile = await imageCompression(file, compressionOptions);

        setImageFile(compressedFile);
        const urlCreator = window.URL || window.webkitURL;
        setImageUrlPreview(urlCreator.createObjectURL(compressedFile));
      } catch (error) {
        processingFailed(
          `Please try again. Error compressing image: ${error.message}`
        );
      }

    } else if (file) {
      clearImage();
      dispatch(showAppAlert({
        message: `Not an image, the file is a ${file.type}.`
      }));
    } else {
      clearImage();
      dispatch(showAppAlert({ message: 'Please select a valid file.' }));
    }
  };

  const handleUploadStateChange = snapshot => {
    const percentComplete =
      (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    setFileProgress(percentComplete);

    switch (snapshot.state) {
      case 'paused':
        dispatch(showAppAlert({ message: 'Upload is paused' }));
        break;
      case 'running':
        break;
      default:
        break;
    }
  };

  const handleUploadError = (error, reject) => {
    const errorMessage = `Error uploading image: ${error.message}`;
    reject({ message: errorMessage });
  };

  const handleUploadComplete = async (uploadTask, resolve, reject) => {
    try {
      const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
      resolve(downloadUrl);
    } catch (error) {
      const errorMessage =
        `Please try again. Error retrieving image url: ${error.message}`;
      reject({ message: errorMessage });
    }
  };

  const handleFireBaseUpload = () => {
    const snakeCaseChoirName = choir.name.toLowerCase().replace(' ', '_');
    const snakeCaseFullName = `${firstName}_${lastName}`.toLowerCase();
    const storageRef = ref(
      storage,
      `/choristers/${snakeCaseChoirName}/${snakeCaseFullName}`
    );

    const uploadTask = uploadBytesResumable(storageRef, imageFile);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed', 
        handleUploadStateChange,
        (error) => handleUploadError(error, reject),
        () => handleUploadComplete(uploadTask, resolve, reject)
      );
    });
  };

  const makeNewChoristerRequestOptions = firebaseImageUrl => ({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      ...chorister,
      photo: firebaseImageUrl
    })
  });

  const processingFailed = errorMessage => {
    dispatch(showAppAlert({ message: errorMessage }));
    resetProcessing();
  };

  const handleSaveNewChorister = async event => {
    event.preventDefault();

    startProcessing();

    try {
      const firebaseImageUrl = await handleFireBaseUpload();
  
      const createOptions = makeNewChoristerRequestOptions(firebaseImageUrl);
      const response = await fetch(`${apiUrl}/choristers`, createOptions);
      const newChorister = await response.json();

      if (newChorister.id) {
        dispatch(addChorister(newChorister));
        dispatch(showAppAlert({
          title: 'Success',
          message: `${newChorister.first_name} added to ${choir.name}`,
          bg: 'success'
        }));
        setTimeoutId(setTimeout(closeModal, 200));
      } else {
        const errorMessage = newChorister.errors
          .reduce((errorString, error, index) => {
            return errorString += `${index + 1}. ${error}\n`;
          }, '');
        processingFailed(errorMessage);
      }

    } catch (error) {
      setTimeoutId(setTimeout(() => processingFailed(error.message), 200));
    }
  };

  const isValidFirstYear = isAlum ? isValidYear(firstYear) : true;
  const isValidLastYear = isAlum ?  isValidYear(lastYear): true;
  const isAnyFieldInvalid =
    !firstName ||
    !lastName ||
    !isValidFirstYear ||
    !isValidLastYear ||
    !imageUrlPreview;

  useEffect(() => {
    const removeTimeout = () => timeoutId && clearTimeout(timeoutId);
    return removeTimeout;
  }, [timeoutId]);

  return (
    <section className='add-chorister-form-container'>
      <h2 className='choir-name'>{ choir.name }</h2>

      <Chorister chorister={ chorister } isPreview />

      <Form className='add-chorister-form'>
        <Form.Group className='mb-3' controlId='formFirstName'>
          <Form.Label>First Name</Form.Label>
          <Form.Control
            isInvalid={ !firstName }
            isValid={ firstName }
            type='text'
            placeholder='First name'
            value={ firstName }
            onChange={ event => setFirstName(event.target.value) }
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formLastName'>
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            isInvalid={ !lastName }
            isValid={ lastName }
            type='text'
            placeholder='Last name'
            value={ lastName }
            onChange={ event => setLastName(event.target.value) }
          />
        </Form.Group>

        {isHonors && (
          <Form.Group className='mb-3' controlId='formIsSenior'>
            <Form.Check
              id='is-senior-switch'
              type='switch'
              label='Is senior?'
              checked={ isSenior }
              onChange={ event => setIsSenior(event.target.checked) }
            />
          </Form.Group>
        )}

        {isAlum && (
          <>
            <Form.Group className='mb-3' controlId='formFirstYear'>
              <Form.Label>First Year</Form.Label>
              <Form.Control
                isInvalid={ !isValidFirstYear }
                isValid={ isValidFirstYear }
                type='text'
                placeholder='First year'
                value={ firstYear }
                onChange={ event => setFirstYear(event.target.value) }
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='formLastYear'>
              <Form.Label>Last Year</Form.Label>
              <Form.Control
                isInvalid={ !isValidLastYear }
                isValid={ isValidLastYear }
                type='text'
                placeholder='Last year'
                value={ lastYear }
                onChange={ event => setLastYear(event.target.value) }
              />
            </Form.Group>
          </>
        )}

        <Form.Group className='mb-3' controlId='formPhotoUpload'>
          <Form.Label>Select Photo</Form.Label>
          <Form.Control
            isInvalid={ !imageUrlPreview }
            isValid={ imageUrlPreview }
            type='file'
            accept='image/*'
            onChange={ handleImageFile }
          />
        </Form.Group>

        <Button
          disabled={ isAnyFieldInvalid || busy }
          variant='primary'
          type='submit'
          onClick={ handleSaveNewChorister }
        >
          Save <FontAwesomeIcon icon={ faSave } />
        </Button>
        {isProcessing && (
          <ProgressBar animated now={ fileProgress } />
        )}
      </Form>
    </section>
  );
}

export default AddChoristerForm;
