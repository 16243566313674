import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { useSelector } from 'react-redux';

import { currentUrlPathString } from '../../../../redux/features/currentUrlPath/currentUrlPathSlice';

import { capitalizeEveryWord } from '../../../../utilities/index';

import './NCNavLink.css';

const NCNavLink = ({ pathName, order }) => {

  const currentUrlPath = useSelector(currentUrlPathString);
  const baseCurrentUrlPathNoSlash = currentUrlPath.split('/')[1];

  const isCurrentPath = path => {
    const basePathNoSlash = path.replace('/', '');
    return baseCurrentUrlPathNoSlash === basePathNoSlash;
  };
  const selectedNavLink = path => {
    return isCurrentPath(path) ? 'selected-nav-link' : '';
  }

  const pathNameNoSpaces = pathName.replaceAll(' ', '-');
  const path = `/${pathNameNoSpaces}`;

  return (
    <Nav.Link
      as={ Link }
      to={ path }
      className={ selectedNavLink(path) }
      eventKey={ order }
      role={ `${pathName} page link` }
    >
      <span>{ capitalizeEveryWord(pathName) }</span>
    </Nav.Link>
  );
}

export default NCNavLink;
