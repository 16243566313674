import { forwardRef } from 'react';

import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { cartItemsFromStore } from '../../../../redux/features/cart/cartSlice';

import CartItem from './CartItem';

import './CartDropdown.css';

function CartDropdown(_ , ref) {

  const cartItems = useSelector(cartItemsFromStore);

  const displayCartItems = () => {
    return cartItems.map((cartItem, index) => {
      return (
        <CartItem
          key={ `${cartItem.id} ${index}` }
          cartItem={ { ...cartItem, index } }
        />
      );
    });
  };

  const isCartEmpty = cartItems.length === 0;

  return (
    <div ref={ ref } className='cart-dropdown'>
        <div className='cart-dropdown-header'>
          <h5 className='cart-dropdown-heading'>Your bag</h5>
          {!isCartEmpty && (
            <Link
              to='checkout'
              role='checkout link'
              className='checkout-button-link'
            >
              <Button className='checkout-button'>
                Checkout
              </Button>
            </Link>
          )}
        </div>

        <div
          className={ `cart-swag-container${ isCartEmpty ? ' cart-empty' : '' }` }
        >
          {cartItems.length
            ? displayCartItems()
            : <Link
              to='swag-&-support'
              role='swag and support link'
              className='swag-support-button-link'
            >
              <Button className='go-to-swag-button'>See our swag</Button>
            </Link>
          }
        </div>
    </div>
  );
};

export default forwardRef(CartDropdown);
