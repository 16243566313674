import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';

import {
  hideModal,
  modalInnerComponentName
} from '../../redux/features/modal/modalSlice';

import modalComponents from './modalComponents';

import './Modal.css';

function Modal() {

  const dispatch = useDispatch();

  const innerComponentName = useSelector(modalInnerComponentName);
  const InnerComponent = modalComponents[innerComponentName];

  const closeModal = () => {
    dispatch(hideModal());
  };

  return (
    <section className='modal'>
      <div className='overlay' onClick={ closeModal }></div>
      <div className='modal-content'>
        <button className='close-modal' onClick={ closeModal }>
          <FontAwesomeIcon icon={ faTimes } />
        </button>
        <InnerComponent closeModal={ closeModal } />
      </div>
    </section>
  );
}

export default Modal;