import { deleteObject, getStorage, ref } from "firebase/storage";

import { removeAudio } from '../../../../redux/features/audios/audiosSlice';
import { showAppAlert } from '../../../../redux/features/appAlert/appAlertSlice';

import { apiUrl } from '../../../../utilities';

export const handleAudioDelete = async (closeModal, dispatch, details) => {
  const { audio } = details;

  const snakeCaseAudioTitle = audio.title.toLowerCase().replaceAll(' ', '_');
  const snakeCaseAudioTitleWithYear = `${snakeCaseAudioTitle}_${audio.year}`;
  const firebaseStorageUrl =
    `/media/audio/${audio.event_type}/${snakeCaseAudioTitleWithYear}`;
  const storage = getStorage();
  const firebaseImageRef = ref(storage, firebaseStorageUrl);

  const audiosUrl = `${apiUrl}/audios/${audio.id}`;
  const deleteOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${localStorage.token}`
    }
  };

  try {
    await deleteObject(firebaseImageRef);
    const response = await fetch(audiosUrl, deleteOptions);

    if (response.ok) {
      dispatch(showAppAlert({
        title: 'Success',
        message: `${audio.title}, ${audio.name} removed`,
        bg: 'success'
      }));
      dispatch(removeAudio({ removedAudio: audio }));
      closeModal();

    } else {
        const { error, exception } = await response.json();
        const errorMessage =
          `Error removing ${audio.title}, ${audio.name}: ${exception || error}`;
        dispatch(showAppAlert({ message: errorMessage }));
    }

  } catch (error) {
    const errorMessage =
      `Error removing ${audio.title}, ${audio.name}: ${error.message}`;
    dispatch(showAppAlert({ message: errorMessage }));
  }
};
