import { useState } from 'react';

import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import { useSelector } from 'react-redux';

import { appAlertInfo } from '../../../redux/features/appAlert/appAlertSlice';

import './NCToast.css';

function NCToast({ bg, message, title, isAutohide }) {

  const [show, setShow] = useState(true);
  const { timeoutId } = useSelector( appAlertInfo );

  const handleClose = () => {
    setShow(false);
    clearTimeout(timeoutId);
  };

  return (
    <ToastContainer className='position-fixed' position='top-center'>
      <Toast
        bg={ bg || 'warning' }
        delay={ 3500 }
        autohide={ isAutohide }
        show={ show }
        onClose={ handleClose }
      >
        <Toast.Header>
          <strong className="me-auto">{ title || 'Alert' }</strong>
        </Toast.Header>
        <Toast.Body>{ message || 'something went wrong' }</Toast.Body>
      </Toast>
    </ToastContainer>
  );
}

export default NCToast;
