import { useDispatch, useSelector } from 'react-redux';

import { authenticated } from '../../../../redux/features/authenticated/authenticatedSlice';
import { displayModal } from '../../../../redux/features/modal/modalSlice';

import DeleteButtonWithTooltip from '../../../generic/DeleteButtonWithTooltip';

import {
  convertDateTimeToMonthWithDay,
  convertDateTimeToTime
} from '../../../../utilities';

import './Event.css';

function Event({ event, isPreview }) {

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(authenticated);

  const confirmEventDelete = () => {
    dispatch(displayModal({
      innerComponentName: 'Confirm',
      backgroundInfo: {
        handleConfirmName: 'handleEventDelete',
        isDelete: true,
        message: `Remove ${event.title} permanently?`,
        details: {
          event,
        }
      }
    }));
  };

  const startTime = convertDateTimeToTime(event.start_date_time);
  const endTime = convertDateTimeToTime(event.end_date_time);

  const monthWithDate = convertDateTimeToMonthWithDay(event.start_date_time);

  return (
    <li className='event'>
      <div className='event-title-and-date'>
        <p className='event-title'>{ event.title }</p>

        <span className='event-date'>{ monthWithDate }</span>
      </div>

      <div className='event-time-and-delete'>
        <span className='event-time'>
          { startTime } - { endTime }
        </span>

        {isAuthenticated && !isPreview && (
          <DeleteButtonWithTooltip
            itemName='event'
            placement='left'
            handleClick={ confirmEventDelete }
          />
        )}
      </div>
    </li>
  );
}

export default Event;
