import { useEffect } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { appAlertInfo } from './redux/features/appAlert/appAlertSlice';
import {
  attemptAuthentication,
} from './redux/features/authenticated/authenticatedSlice';
import { isModalShown } from './redux/features/modal/modalSlice';
import { setCurrentUrlPath } from './redux/features/currentUrlPath/currentUrlPathSlice';

import NCToast from './components/generic/NCToast';
import Modal from './components/Modal';

import Header from './components/Header';
import Home from './components/pages/Home';
import Choirs from './components/pages/Choirs';
import Choir from './components/pages/Choirs/Choir';
import Staff from './components/pages/Staff';
import StaffMember from './components/pages/Staff/StaffMember';
import Events from './components/pages/Events';
import Media from './components/pages/Media';
import Videos from './components/pages/Media/Videos';
import VideosContainer from './components/pages/Media/Videos/VideosTab/VideosContainer';
import Audios from './components/pages/Media/Audios';
import AudiosContainer from './components/pages/Media/Audios/AudiosContainer';
import ChoirVibes from './components/pages/Media/ChoirVibes';
import SwagAndSupport from './components/pages/SwagAndSupport';
import Contact from './components/pages/Contact';
import Checkout from './components/pages/Checkout';
import Login from './components/pages/Login';
import PageNotFound from './components/pages/PageNotFound';
import Footer from './components/Footer';
import SiteAudioPlaylistContainer from './components/generic/SiteAudioPlaylistContainer';

import { apiUrl } from './utilities';

import './App.css';

function App() {

  const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorage.token;
    if (token) {
      dispatch(attemptAuthentication(token));
    }

    fetch(`${apiUrl}/staffs`); // wake up Heroku
  }, [dispatch]);

  const location = useLocation();
  useEffect(() => {
    dispatch(setCurrentUrlPath(new URL(window.location).pathname));
  }, [location, dispatch]);

  const appAlert = useSelector(appAlertInfo);
  const showModal = useSelector(isModalShown);

  return (
    <main className='App'>
      {appAlert.message && (
        <NCToast
          title={ appAlert.title }
          message={ appAlert.message }
          bg={ appAlert.bg }
          isAutohide={ appAlert.isAutohide }
        /> 
      )}

      { showModal && <Modal /> }

      <Header />

      <Routes>
        <Route exact path='' element={ <Home /> } />

        <Route path='choirs' element={ <Choirs /> }>
          <Route index element={ <Navigate to='nighthawk-honors' replace /> } />
          <Route path=':choirNameDashed' element={ <Choir /> } />
        </Route>

        <Route path='staff' element={ <Staff /> }>
          <Route index element={ <Navigate to='director' replace /> } />
          <Route path=':staffMemberNameDashed' element={ <StaffMember /> } />
        </Route>

        <Route path='events' element={ <Events /> } />

        <Route path='media/*' element={ <Media /> }>
          <Route index element={ <Navigate to='videos' replace /> } />

          <Route path='videos' element={ <Videos /> }>
            <Route index element={ <Navigate to='events' replace /> } />
            <Route path=':eventType' element={ <VideosContainer /> } />
          </Route>

          <Route path='audio' element={ <Audios /> }>
            <Route index element={ <Navigate to='events' replace /> } />
            <Route path=':eventType' element={ <AudiosContainer /> } />
          </Route>

          <Route path='choir-vibes' element={ <ChoirVibes /> } />

          <Route path='*' element={ <Navigate to='videos' replace /> } />
        </Route>

        <Route path='swag-&-support' element={ <SwagAndSupport /> } />

        <Route path='contact' element={ <Contact /> } />

        <Route path='checkout' element={ <Checkout /> } />

        <Route path='steele' element={ <Login /> } />

        <Route path='*' element={ <PageNotFound /> } />
      </Routes>

      <Footer />

      <SiteAudioPlaylistContainer />
    </main>
  );
}

export default App;
