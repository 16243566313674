import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiUrl } from '../../../utilities';

export const getVideos = createAsyncThunk(
  'app/getVideos',
  async () => {
    try {
      const response = await fetch(`${apiUrl}/videos`);
      const videos = await response.json();
      return { isLoading: false, videos };
    } catch(error) {
      console.error(`Error retrieving videos: ${error.message}`);
    }
  }
);

const sortByYearDesc = (a, b) => b.year - a.year;

export const videosSlice = createSlice({
  name: 'videos',
  initialState: {
    isLoading: true,
    videos: []
  },
  reducers: {
    addVideo(state, action) {
      const newVideo = action.payload;
      state.videos = [newVideo, ...state.videos]
        .sort(sortByYearDesc);
    },
    removeVideo(state, action) {
      const removedVideo = action.payload;
      state.videos = state.videos.filter(video => {
        return video.id !== removedVideo.id;
      });
    }
  },
  extraReducers: builder => {
    builder.addCase(
      getVideos.fulfilled,
      (_, action) => action.payload
    );
  }
});

export const videosInfo = state => state.videos;

export const { addVideo, removeVideo } = videosSlice.actions;

export default videosSlice.reducer;
