import { useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useDispatch, useSelector } from 'react-redux';

import { addAudio } from '../../../../redux/features/audios/audiosSlice';
import { modalBackgroundInfo } from '../../../../redux/features/modal/modalSlice';
import { showAppAlert } from '../../../../redux/features/appAlert/appAlertSlice';

import AudioPlayer from '../AudioPlayer';

import { apiUrl, isValidYear } from '../../../../utilities';
import {
  storage,
  ref,
  uploadBytesResumable,
  getDownloadURL
} from '../../../../firebase/firebase';

import './AddAudioForm.css';

function AddAudioForm({ closeModal }) {

  const dispatch = useDispatch();
  const { eventType: audioEventType } = useSelector(modalBackgroundInfo);

  const [busy, setBusy] = useState(false);
  const [audioPlayingId, setAudioPlayingId] = useState(0);

  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [year, setYear] = useState('');

  const [audioFile, setAudioFile] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [fileProgress, setFileProgress] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);

  const [audioUrlPreview, setAudioUrlPreview] = useState('');

  const audio = {
    title,
    name,
    year,
    event_type: audioEventType,
    url: audioUrlPreview
  };

  const clearAudio = () => {
    setAudioFile('');
    setAudioUrlPreview('');
  };

  const startProcessing = () => {
    setBusy(true);
    setIsProcessing(true);
  };

  const resetProcessing = () => {
    setBusy(false);
    setIsProcessing(false);
    setFileProgress(0);
  };

  const processingFailed = errorMessage => {
    dispatch(showAppAlert({ message: errorMessage }));
    resetProcessing();
  };

  const handleAudioFile = async event => {
    const file = event.target.files[0];
    if (file instanceof File && file?.type.includes('audio/mpeg')) {
      setAudioFile(file);
      const urlCreator = window.URL || window.webkitURL;
      setAudioUrlPreview(urlCreator.createObjectURL(file));

    } else if (file) {
      clearAudio();
      dispatch(showAppAlert({
        message: `Not an mp3, the file is a ${file.type}.`
      }));
    } else {
      clearAudio();
      dispatch(showAppAlert({ message: 'Please select a valid file.' }));
    }
  };

  const handleUploadStateChange = snapshot => {
    const percentComplete =
      (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    setFileProgress(percentComplete);

    switch (snapshot.state) {
      case 'paused':
        dispatch(showAppAlert({ message: 'Upload is paused' }));
        break;
      case 'running':
        break;
      default:
        break;
    }
  };

  const handleUploadError = (error, reject) => {
    const errorMessage = `Error uploading audio: ${error.message}`;
    reject({ message: errorMessage });
  };

  const handleUploadComplete = async (uploadTask, resolve, reject) => {
    try {
      const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
      resolve(downloadUrl);
    } catch (error) {
      const errorMessage =
        `Please try again. Error retrieving audio url: ${error.message}`;
      reject({ message: errorMessage });
    }
  };

  const handleFireBaseUpload = () => {
    const snakeCaseAudioTitle = audio.title.toLowerCase().replaceAll(' ', '_');
    const snakeCaseAudioTitleWithYear = `${snakeCaseAudioTitle}_${audio.year}`;
    const storageRef = ref(
      storage,
      `/media/audio/${audioEventType}/${snakeCaseAudioTitleWithYear}`
    );

    const uploadTask = uploadBytesResumable(storageRef, audioFile);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed', 
        handleUploadStateChange,
        (error) => handleUploadError(error, reject),
        () => handleUploadComplete(uploadTask, resolve, reject)
      );
    });
  };

  const makeNewAudioRequestOptions = firebaseAudioUrl => ({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ audio: {
      ...audio,
      url: firebaseAudioUrl
    }})
  });

  const handleSaveNewAudio = async event => {
    event.preventDefault();

    startProcessing();

    try {
      const firebaseAudioUrl = await handleFireBaseUpload();

      const createOptions = makeNewAudioRequestOptions(firebaseAudioUrl);
      const response = await fetch(`${apiUrl}/audios`, createOptions);
      const newAudio = await response.json();

      if (newAudio.id) {
        dispatch(addAudio({ newAudio }));
        dispatch(showAppAlert({
          title: 'Success',
          message: `${newAudio.title}, ${newAudio.name} added`,
          bg: 'success'
        }));
        setTimeoutId(setTimeout(closeModal, 200));
      } else {
        const errorMessage = newAudio.errors
          .reduce((errorString, error, index) => {
            return errorString += `${index + 1}. ${error}\n`;
          }, '');
        processingFailed(errorMessage);
      }

    } catch (error) {
      setTimeoutId(setTimeout(() => processingFailed(error.message), 200));
    }
  };

  const isValidYearVariable = isValidYear(year);
  const isAnyFieldInvalid =
    !title ||
    !name ||
    !isValidYearVariable ||
    !audioUrlPreview;

  const eventTypes = {
    events: 'DPS Events',
    concerts: 'Northfield HS Concerts',
    graduation: 'Graduation'
  };

  useEffect(() => {
    const removeTimeout = () => timeoutId && clearTimeout(timeoutId);
    return removeTimeout;
  }, [timeoutId]);

  return (
    <section className='add-audio-form-container'>
      <h2 className='audio-event-type'>{ eventTypes[audioEventType] }</h2>

      <AudioPlayer
        audio={ audio }
        audioPlayingId={ audioPlayingId }
        setAudioPlayingId={ setAudioPlayingId }
        isPreview
      />

      <Form className='add-audio-form'>
        <Form.Group className='mb-3' controlId='formTitle'>
          <Form.Label>Title</Form.Label>
          <Form.Control
            isInvalid={ !title }
            isValid={ title }
            type='text'
            placeholder='Title'
            value={ title }
            onChange={ event => setTitle(event.target.value) }
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formName'>
          <Form.Label>Name</Form.Label>
          <Form.Control
            isInvalid={ !name }
            isValid={ name }
            type='text'
            placeholder='Name'
            value={ name }
            onChange={ event => setName(event.target.value) }
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formYear'>
          <Form.Label>Year</Form.Label>
          <Form.Control
            isInvalid={ !isValidYearVariable }
            isValid={ isValidYearVariable }
            type='text'
            placeholder='Year'
            value={ year }
            onChange={ event => setYear(event.target.value) }
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formPhotoUpload'>
          <Form.Label>Select mp3</Form.Label>
          <Form.Control
            isInvalid={ !audioUrlPreview }
            isValid={ audioUrlPreview }
            type='file'
            accept='.mp3'
            onChange={ handleAudioFile }
          />
        </Form.Group>

        <Button
          disabled={ isAnyFieldInvalid || busy }
          variant='primary'
          type='submit'
          onClick={ handleSaveNewAudio }
        >
          Save <FontAwesomeIcon icon={ faSave } />
        </Button>
        {isProcessing && (
          <ProgressBar animated now={ fileProgress } />
        )}
      </Form>
    </section>
  );
}

export default AddAudioForm;
