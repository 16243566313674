import { useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';

import { displayModal } from '../../../../../redux/features/modal/modalSlice';

import EditButtonWithTooltip from '../../../../generic/EditButtonWithTooltip';

import './HomeGalleryPhoto.css';

function HomeGalleryPhoto({ homeGalleryPhoto }) {

  const dispatch = useDispatch();

  const imageRef = useRef();
  const [isGalleryVisible, setIsGalleryVisible] = useState(false);

  useEffect(() => {
    if (imageRef.current) {
      const handleObserver = entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsGalleryVisible(true);
            elementsObserver.unobserve(imageRef.current);
          }
        });
      };
      const observeOptions = { threshold: 0.50 };
      const elementsObserver = new IntersectionObserver(
        handleObserver,
        observeOptions
      );
      elementsObserver.observe(imageRef.current);
  
      return () => elementsObserver.disconnect();
    }
  }, []);

  const showEditHomeGalleryPhotoModal = () => {
    dispatch(displayModal({
      innerComponentName: 'EditHomeGalleryPhotoForm',
      backgroundInfo: { homeGalleryPhoto }
    }));
  };

  return (
    <div key={ homeGalleryPhoto.id } className='gallery-item-container'>
      <EditButtonWithTooltip
        isAdminOnly
        itemName='home-gallery-photo'
        tooltipText='Change photo'
        placement='top'
        handleClick={ showEditHomeGalleryPhotoModal }
      />
      
      <h4 className={`gallery-item-heading${ isGalleryVisible ? ' is-visible' : '' }`}>
        { homeGalleryPhoto.title }
      </h4>

      <img
        ref={ imageRef }
        className={`gallery-item-image${ isGalleryVisible ? ' is-visible' : '' }`}
        src={ homeGalleryPhoto.photo }
        alt={ `home gallery ${homeGalleryPhoto.order}` }
      />
    </div>
  );
}

export default HomeGalleryPhoto;
