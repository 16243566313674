import { useEffect } from 'react';

import Button from 'react-bootstrap/Button';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';

import { authenticated } from '../../../../redux/features/authenticated/authenticatedSlice';
import { displayModal } from '../../../../redux/features/modal/modalSlice';
import {
  getSwags,
  swagInfo
} from '../../../../redux/features/swags/swagsSlice';

import Loading from '../../../generic/Loading';
import SwagItem from '../SwagItem';

import './Swag.css';

function Swag() {

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(authenticated);
  const { isLoading, swags } = useSelector(swagInfo);

  useEffect(() => {
    if (!swags.length) {
      dispatch(getSwags());
    }
  }, [dispatch, swags.length]);

  const showAddSwagModal = () => {
    const innerComponentName = 'AddOrEditSwagItemForm';
    dispatch(displayModal({ innerComponentName }));
  };

  const displaySwag = () => {
    return swags.map(swag => {
      return <SwagItem key={ swag.id } swag={ swag } />
    });
  };

  return (
    <div className='swag-container'>
      <div className='swag-heading-and-add-swag-container'>
        <h2 className='swag-heading'>Swag</h2>

        {isAuthenticated && (
          <Button
            className='add-swag'
            variant='outline-primary'
            onClick={ showAddSwagModal }
          >
            <FontAwesomeIcon icon={ faPlus } />
          </Button>
        )}
      </div>

      {isLoading
        ? <Loading />
        : <div className='swag-items'>
          { displaySwag() }
        </div>
      }

    </div>
  );
}

export default Swag;
