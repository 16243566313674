import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiUrl } from '../../../utilities';

export const getGoogleCalendarKeys = createAsyncThunk(
  'google/getKeys',
  async () => {
    try {
      const response = await fetch(`${apiUrl}/keys`);
      const { calendar_id, calendar_key } = await response.json();
      return {
        isLoading: false,
        googleCalendarId: calendar_id,
        googleCalendarApiKey: calendar_key
      };
    } catch(error) {
      console.error(`Error retrieving calendar keys: ${error.message}`);
    }
  }
);

export const googleCalendarKeysSlice = createSlice({
  name: 'googleCalendar',
  initialState: {
    isLoading: true,
    googleCalendarId: '',
    googleCalendarApiKey: ''
  },
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(
      getGoogleCalendarKeys.fulfilled,
      (_, action) => action.payload
    );
  }
});

export const googleCalendarKeys = state => state.googleCalendarKeys;

export default googleCalendarKeysSlice.reducer;
