import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';

import { modalBackgroundInfo } from '../../../redux/features/modal/modalSlice';

import confirmFunctions from './confirmFunctions';

import './Confirm.css';

function Confirm({ closeModal }) {

  const dispatch = useDispatch();
  const {
    handleConfirmName,
    isDelete,
    message,
    details,
    displayImage
  } = useSelector(modalBackgroundInfo);

  const executeConfirm = () => {
    confirmFunctions[handleConfirmName](closeModal, dispatch, details || {});
  };

  return (
    <>
      <Modal.Title>Confirm</Modal.Title>
      <Modal.Body>
        { message || 'Are you sure?' }
        {displayImage?.src && <img
          className='confirm-cart-swag-image'
          src={ displayImage.src }
          alt={ displayImage.alt }
        />}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={ closeModal }>
          Cancel
        </Button>
        <Button
          variant={ isDelete ? 'danger' : 'primary' }
          onClick={ executeConfirm }
        >
          { isDelete ? 'Remove' : 'Confirm' }
        </Button>
      </Modal.Footer>
    </>
  );
}

export default Confirm;
