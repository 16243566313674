import { useEffect, useRef, useState } from 'react';

import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getSwags, swagInfo } from '../../../../redux/features/swags/swagsSlice';

import './HomeSupport.css';

import paypalButton from '../../../../assets/images/paypal-donate-button.png';
import venmoLogo from '../../../../assets/images/venmo-logo.png';

function HomeSupport() {

  const dispatch = useDispatch();
  const { swags } = useSelector(swagInfo);
  const mainSwag = swags[0];

  useEffect(() => {
    if (!swags.length) {
      dispatch(getSwags());
    }
  }, [dispatch, swags.length]);

  const titleRef = useRef();
  const imageRef = useRef();

  const [isTitleVisible, setIsTitleVisible] = useState(false);
  const [isFirstImageVisible, setIsFirstImageVisible] = useState(false);

  useEffect(() => {
    const handleObserver = entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          if (entry.target.className.includes('title')) {
            setIsTitleVisible(true);
            elementsObserver.unobserve(titleRef.current);
          } else if (entry.target.className.includes('image')) {
            setIsFirstImageVisible(true);
            elementsObserver.unobserve(imageRef.current);
          }
        }
      });
    };
    const observeOptions = { threshold: 0.50 };
    const elementsObserver = new IntersectionObserver(
      handleObserver,
      observeOptions
    );
    elementsObserver.observe(titleRef.current);
    elementsObserver.observe(imageRef.current);

    return () => elementsObserver.disconnect();
  }, []);

  return (
    <div id='swag-&-support' className='home-swag-support'>
      <h3
        ref={ titleRef }
        className={`home-swag-support-title${ isTitleVisible ? ' is-visible' : '' }`}
      >
        Support us
      </h3>

      <div className='home-swag-support-container'>
        <div className='home-support-container'>
          <Link
            to='swag-&-support'
            role='swag and support link'
            className='swag-support-link'
          >
            <h4 className={`home-support-title${ isFirstImageVisible ? ' is-visible' : '' }`}>
              Donate now
            </h4>
          </Link>

          <div className='home-support-images'>
            <Link
              to='swag-&-support'
              role='swag and support link'
              className='swag-support-link'
            >
              <img
                className={`home-support-image${ isFirstImageVisible ? ' is-visible' : '' }`}
                ref={ imageRef }
                src={ paypalButton }
                alt='paypal logo'
              />
            </Link>

            <Link
              to='swag-&-support'
              role='swag and support link'
              className='swag-support-link'
            >
              <img
                className={`home-support-image${ isFirstImageVisible ? ' is-visible' : '' }`}
                src={ venmoLogo }
                alt='venmo logo'
              />
            </Link>
          </div>
        </div>

        <div className='home-swag-container'>
          <Link
            to='swag-&-support'
            role='swag and support link'
            className='swag-support-link'
          >
            <h4 className={`home-swag-title${ isFirstImageVisible ? ' is-visible' : '' }`}>
              Sport our gear
            </h4>
          </Link>

          {mainSwag && (
            <Link
              to='swag-&-support'
              role='swag and support link'
              className='swag-support-link'
            >
              <img
                className={`home-swag-image${ isFirstImageVisible ? ' is-visible' : '' }`}
                src={ mainSwag.image }
                alt={ `${mainSwag.group} ${mainSwag.category}`}
              />
            </Link>
          )}
        </div>
      </div>

      <Link
        to='swag-&-support'
        role='swag and support link'
        className={`swag-support-button-link${isFirstImageVisible ? ' is-visible' : ''}`}
      >
        <Button
          className='go-to-swag-support-button'
          variant='outline-warning'
          role='link'
        >
          See more options
        </Button>
      </Link>
    </div>
  );
}

export default HomeSupport;
