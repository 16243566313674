import { useEffect } from 'react';

import { Outlet, useNavigate, useParams } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import './Media.css';

function Media() {

  const params = useParams();
  const urlPath = params['*'].split('/')[0];
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const handleMediaSelect = eventKey => {
    navigate(eventKey);
  };

  return (
    <section className='media'>
      <h2 className='media-heading'>Past Performances</h2>
      <Tabs
        defaultActiveKey='videos'
        id='media-tabs'
        className='mb-3'
        activeKey={ urlPath }
        onSelect={ handleMediaSelect }
      >
        <Tab
          key='videos'
          eventKey='videos'
          title='Videos'
        >
          <Outlet />
        </Tab>
        <Tab
          key='audio'
          eventKey='audio'
          title='Audio'
        >
          <Outlet />
        </Tab>
        <Tab
          key='choir-vibes'
          eventKey='choir-vibes'
          title='Choir Vibes'
        >
          <Outlet />
        </Tab>
      </Tabs>
    </section>
  );
}

export default Media;
