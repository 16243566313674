import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { loginSuccess } from '../authenticated/authenticatedSlice';

import { apiUrl } from '../../../utilities';

export const attemptLogin = createAsyncThunk(
  'admin/login',
  async ({ username, password }, thunkAPI) => {
    try {
      const response = await fetch(`${apiUrl}/login`, {
        method: 'POST',
        body: JSON.stringify({ admin: { username, password } }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      const result = await response.json();
      const { token } = result;
      if (token) {
        localStorage.setItem('token', token);
        thunkAPI.dispatch(loginSuccess());
      }
      return result;
    } catch(error) {
      console.error(`Error logging in: ${error.message}`);
      return { error: error.message };
    }
  }
);

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    token: ''
  },
  reducers: {
    storeValidToken(state, action) {
      state.token = action.payload;
    },
    removeToken(state) {
      state.token = '';
    }
  },
  extraReducers: builder => {
    builder.addCase(
      attemptLogin.fulfilled,
      (_, action) => action.payload
    );
  }
});

export const loginInfo = state => state.login;

export const { storeValidToken, removeToken } = loginSlice.actions;

export default loginSlice.reducer;
