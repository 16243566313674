import { useEffect } from 'react';

import Button from 'react-bootstrap/Button';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Nav from 'react-bootstrap/Nav';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { authenticated } from '../../../../redux/features/authenticated/authenticatedSlice';
import {
  audiosInfo,
  getAudios
} from '../../../../redux/features/audios/audiosSlice';
import { displayModal } from '../../../../redux/features/modal/modalSlice';

import Loading from '../../../generic/Loading';

import './Audios.css';

function Audios() {

  const { eventType } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(authenticated);
  const { isLoading, audios } = useSelector(audiosInfo);

  useEffect(() => {
    if (!audios.length) {
      dispatch(getAudios());
    }
  }, [dispatch, audios.length]);

  const showAddAudioModal = () => {
    const innerComponentName = 'AddAudioForm';
    const backgroundInfo = { eventType };
    dispatch(displayModal({ innerComponentName, backgroundInfo }));
  };

  const handlePillSelect = eventKey => {
    navigate(eventKey);
  };

  return (
    <>
      {isLoading
        ? <Loading />
        : <div className='media-tab-audios'>
          <div className='media-pills-and-add-media-button-container'>
            <Nav
              variant='pills'
              role='audio-type-selector'
              defaultActiveKey='events'
              activeKey={ eventType }
              onSelect={ handlePillSelect }
            >
              <Nav.Item>
                <Nav.Link as='button' eventKey='events'>
                  DPS Events
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as='button' eventKey='concerts'>
                  Northfield HS Concerts
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as='button' eventKey='graduation'>
                  Graduation
                </Nav.Link>
              </Nav.Item>
            </Nav>

            {isAuthenticated && (
              <Button
                className='add-audio'
                variant='outline-primary'
                onClick={ showAddAudioModal }
              >
                <FontAwesomeIcon icon={ faPlus } />
              </Button>
            )}
          </div>

          <Outlet context={ { audios } } />

        </div>
      }
    </>
  );
}

export default Audios;
