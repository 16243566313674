import { handleAudioDelete } from '../../pages/Media/AudioPlayer/audioUtilities';
import { handleChoirVibesPhotoDelete } from '../../pages/Media/ChoirVibes/ChoirVibesPhoto/choirVibesPhotoUtilities';
import { handleChoristerDelete } from '../../pages/Choirs/Chorister/choristerUtilities';
import { handleEventDelete } from '../../pages/Events/Event/eventUtilities';
import { handleHomeCarouselPhotoDelete } from '../../pages/Home/HomeCarousel/HomeCarouselPhoto/homeCarouselPhotoUtilities';
import { handleLogoutConfirm } from '../../Header';
import { handleRemoveItemFromCart } from '../../Header/Cart/CartDropdown/CartItem';
import { handleSwagItemDelete } from '../../pages/SwagAndSupport/SwagItem/swagItemUtilities';
import { handleVideoDelete } from '../../pages/Media/VideoPlayer/videoUtilities';

const confirmFunctions = {
  'handleAudioDelete': handleAudioDelete,
  'handleChoirVibesPhotoDelete': handleChoirVibesPhotoDelete,
  'handleChoristerDelete': handleChoristerDelete,
  'handleEventDelete': handleEventDelete,
  'handleHomeCarouselPhotoDelete': handleHomeCarouselPhotoDelete,
  'handleLogoutConfirm': handleLogoutConfirm,
  'handleRemoveItemFromCart': handleRemoveItemFromCart,
  'handleSwagItemDelete': handleSwagItemDelete,
  'handleVideoDelete': handleVideoDelete
};

export default confirmFunctions;
