import { deleteObject, getStorage, ref } from "firebase/storage";

import { removeChorister } from '../../../../redux/features/choirs/choirsSlice';
import { showAppAlert } from '../../../../redux/features/appAlert/appAlertSlice';

import { apiUrl } from '../../../../utilities';

export const handleChoristerDelete = async (closeModal, dispatch, details) => {
  const { choirName, chorister } = details;
  const { first_name, last_name } = chorister;

  const snakeCaseChoirName = choirName.toLowerCase().replace(' ', '_');
  const snakeCaseFullName = `${first_name}_${last_name}`.toLowerCase();
  const firebaseStorageUrl = `/choristers/${snakeCaseChoirName}/${snakeCaseFullName}`;
  const storage = getStorage();
  const firebaseImageRef = ref(storage, firebaseStorageUrl);

  const choristerUrl = `${apiUrl}/choristers/${chorister.id}`;
  const deleteOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${localStorage.token}`
    }
  };

  const fullName = `${first_name} ${last_name}`;

  try {
    await deleteObject(firebaseImageRef);
    const response = await fetch(choristerUrl, deleteOptions);

    if (response.ok) {
      dispatch(showAppAlert({
        title: 'Success',
        message: `${fullName} removed from ${choirName}`,
        bg: 'success'
      }));
      dispatch(removeChorister(chorister));
      closeModal();

    } else {
        const { error, exception } = await response.json();
        const errorMessage =
          `Error removing ${fullName}: ${exception || error}`;
        dispatch(showAppAlert({ message: errorMessage }));
    }

  } catch (error) {
    const errorMessage = `Error removing ${fullName}: ${error.message}`;
    dispatch(showAppAlert({ message: errorMessage }));
  }
};
