import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiUrl, sortByOrder } from '../../../utilities';

export const getHomeCarouselPhotos = createAsyncThunk(
  'app/getHomeCarouselPhotos',
  async () => {
    try {
      const response = await fetch(`${apiUrl}/home_carousel_photos`);
      const homeCarouselPhotos = await response.json();
      return { isLoading: false, homeCarouselPhotos };
    } catch(error) {
      console.error(`Error retrieving home carousel photos: ${error.message}`);
    }
  }
);

export const homeCarouselPhotosSlice = createSlice({
  name: 'homeCarouselPhotos',
  initialState: {
    isLoading: true,
    homeCarouselPhotos: []
  },
  reducers: {
    addHomeCarouselPhoto(state, action) {
      const { newHomeCarouselPhoto, orderUpdates } = action.payload;

      if (orderUpdates.length) {
        state.homeCarouselPhotos = [...state.homeCarouselPhotos, newHomeCarouselPhoto]
          .map(homeCarouselPhoto => {
            const homeCarouselPhotoOrderUpdate = orderUpdates.find(orderUpdate => {
              return homeCarouselPhoto.id === orderUpdate.id;
            });

            return homeCarouselPhotoOrderUpdate
              ? { ...homeCarouselPhoto, ...homeCarouselPhotoOrderUpdate }
              : homeCarouselPhoto;
          })
          .sort(sortByOrder);
      } else {
        state.homeCarouselPhotos = [...state.homeCarouselPhotos, newHomeCarouselPhoto];
      }
    },
    removeHomeCarouselPhoto(state, action) {
      const { removedHomeCarouselPhoto, orderUpdates } = action.payload;

      let remainingHomeCarouselPhotos = state.homeCarouselPhotos.filter(homeCarouselPhoto => {
        return homeCarouselPhoto.id !== removedHomeCarouselPhoto.id;
      });
      if (orderUpdates.length) {
        remainingHomeCarouselPhotos = remainingHomeCarouselPhotos
          .map(homeCarouselPhoto => {
            const homeCarouselPhotoOrderUpdate = orderUpdates.find(orderUpdate => {
              return homeCarouselPhoto.id === orderUpdate.id;
            });

            return homeCarouselPhotoOrderUpdate
              ? { ...homeCarouselPhoto, ...homeCarouselPhotoOrderUpdate }
              : homeCarouselPhoto;
          })
          .sort(sortByOrder);
      }
      state.homeCarouselPhotos = remainingHomeCarouselPhotos;
    },
    updateHomeCarouselPhoto(state, action) {
      const { updatedHomeCarouselPhoto, orderUpdates } = action.payload;

      state.homeCarouselPhotos = state.homeCarouselPhotos
        .map(homeCarouselPhoto => {
          const homeCarouselPhotoOrderUpdate = orderUpdates.find(orderUpdate => {
            return homeCarouselPhoto.id === orderUpdate.id;
          });

          if (homeCarouselPhotoOrderUpdate) {
            return { ...homeCarouselPhoto, ...homeCarouselPhotoOrderUpdate };
          } else if (homeCarouselPhoto.id === updatedHomeCarouselPhoto.id) {
            return { ...homeCarouselPhoto, ...updatedHomeCarouselPhoto };
          } else {
            return homeCarouselPhoto;
          }
        })
        .sort(sortByOrder);
    },
  },
  extraReducers: builder => {
    builder.addCase(
      getHomeCarouselPhotos.fulfilled,
      (_, action) => action.payload
    );
  }
});

export const homeCarouselPhotosInfo = state => state.homeCarouselPhotos;
export const homeCarouselPhotosLength = state => state.homeCarouselPhotos.homeCarouselPhotos.length;

export const {
  addHomeCarouselPhoto,
  removeHomeCarouselPhoto,
  updateHomeCarouselPhoto
} = homeCarouselPhotosSlice.actions;

export default homeCarouselPhotosSlice.reducer;
