import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiUrl } from '../../../utilities';

export const getHomeVideo = createAsyncThunk(
  'app/getHomeVideo',
  async () => {
    try {
      const response = await fetch(`${apiUrl}/home_videos`);
      const homeVideo = await response.json();
      return { isLoading: false, homeVideo };
    } catch(error) {
      console.error(`Error retrieving home carousel photos: ${error.message}`);
    }
  }
);

export const homeVideoSlice = createSlice({
  name: 'homeVideo',
  initialState: {
    isLoading: true,
    homeVideo: {}
  },
  reducers: {
    updateHomeVideo(state, action) {
      const { updatedHomeVideo } = action.payload;
      state.homeVideo = {
        ...state.homeVideo,
        ...updatedHomeVideo
      };
    }
  },
  extraReducers: builder => {
    builder.addCase(
      getHomeVideo.fulfilled,
      (_, action) => action.payload
    );
  }
});

export const homeVideoInfo = state => state.homeVideo;

export const { updateHomeVideo } = homeVideoSlice.actions;

export default homeVideoSlice.reducer;
