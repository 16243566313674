import {
  storage,
  ref,
  deleteObject
} from "../../../../firebase/firebase";

import { removeSwag } from "../../../../redux/features/swags/swagsSlice";
import { showAppAlert } from '../../../../redux/features/appAlert/appAlertSlice';

import { apiUrl } from '../../../../utilities';

export const handleSwagItemDelete = async (closeModal, dispatch, details) => {
  const { swag } = details;

  const firebaseStorageUrl = `/swag/${swag.category} - ${swag.group}`;
  const firebaseImageRef = ref(storage, firebaseStorageUrl);

  const swagUrl = `${apiUrl}/swags/${swag.id}`;
  const deleteOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${localStorage.token}`
    }
  };

  const imageName = `${swag.group} ${swag.category}`;

  try {
    await deleteObject(firebaseImageRef);
    const response = await fetch(swagUrl, deleteOptions);
    const { order_updates: orderUpdates } = await response.json();

    if (response.ok) {
      dispatch(showAppAlert({
        title: 'Success',
        message: `${imageName} removed`,
        bg: 'success'
      }));
      dispatch(removeSwag({ removedSwag: swag, orderUpdates }));
      closeModal();

    } else {
        const { error, exception } = await response.json();
        const errorMessage =
          `Error removing ${imageName}: ${exception || error}`;
        dispatch(showAppAlert({ message: errorMessage }));
    }

  } catch (error) {
    const errorMessage = `Error removing ${imageName}: ${error.message}`;
    dispatch(showAppAlert({ message: errorMessage }));
  }
};
