import { useEffect, useState } from 'react';

import ReactPlayer from 'react-player/lazy';
import { useDispatch, useSelector } from 'react-redux';

import { authenticated } from '../../../../redux/features/authenticated/authenticatedSlice';
import { displayModal } from '../../../../redux/features/modal/modalSlice';

import DeleteButtonWithTooltip from '../../../generic/DeleteButtonWithTooltip';
import Loading from '../../../generic/Loading';

import './VideoPlayer.css';

function VideoPlayer({
  video,
  videoPlayingId,
  setVideoPlayingId,
  customClass = '',
  isHomePage,
  isPreview,
  isChoir
}) {

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(authenticated);

  const [isLoading, setIsLoading] = useState(true);
  const [videoHeight, setVideoHeight] = useState(361);
  const [timeoutId, setTimeoutId] = useState(0);

  useEffect(() => {
    return () => clearTimeout(timeoutId);
  }, [timeoutId]);

  const confirmVideoDelete = () => {
    dispatch(displayModal({
      innerComponentName: 'Confirm',
      backgroundInfo: {
        handleConfirmName: 'handleVideoDelete',
        isDelete: true,
        message: `Remove ${video.title}, ${video.name} permanently?`,
        details: {
          video
        }
      }
    }));
  };

  let nonPreviewProps = {};
  if (!isPreview) {
    nonPreviewProps = {
      playing: video.id === videoPlayingId,
      onPlay: () => setVideoPlayingId(video.id),
      onPause: () => videoPlayingId === video.id && setVideoPlayingId(0)
    };
  }

  let homePageStyles = {};
  if (isHomePage) {
    homePageStyles = {
      maxHeight: '65vh',
      margin: '0 auto'
    };
  }

  let choirPageStyles = {};
  if (isChoir) {
    choirPageStyles = { maxHeight: '65vh' };
  }

  return (
    <div key={ video.id } className={'video-container' + customClass}>
      <div className='video-heading-container'>
        <div className='video-title-and-delete-container'>
          <h3 className='video-title'>{ video.title || 'Title' }</h3>

          {isAuthenticated && !isHomePage && !isPreview && !isChoir && (
            <DeleteButtonWithTooltip
              itemName='video'
              placement='right'
              handleClick={ confirmVideoDelete }
            />
          )}
        </div>

        <div className='video-details-container'>
          <span className='video-sub-title'>{ video.name || 'Name' }</span>

          {(!!video.year || isPreview) && (
            <span className='video-year'>
              { isPreview ? (video.year || 'year') : video.year }
            </span>
          )}
        </div>
      </div>

      { (isLoading || !video.url) && <Loading isPreview /> }
      <ReactPlayer
        url={ video.url }
        className={
          `video-player-container${isPreview ? ' preview' : ''}`
        }
        width={isHomePage || isChoir ? 'unset' : '100%'}
        height={ videoHeight }
        style={{
          aspectRatio: 'auto 16 / 9',
          maxWidth: '100%',
          display: (isLoading || !video.url) ? 'none' : 'block',
          ...homePageStyles,
          ...choirPageStyles
        }}
        onReady={ () => {
          setIsLoading(false);
          const newTimeoutId = setTimeout(() => {
            setVideoHeight('auto');
          }, 100);
          setTimeoutId(newTimeoutId);
        }}
        { ...nonPreviewProps }
        controls
      />
    </div>
  );
}

export default VideoPlayer;
