import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { storeValidToken } from '../login/loginSlice';

import { apiUrl } from '../../../utilities';

export const attemptAuthentication = createAsyncThunk(
  'admin/authentication',
  async (previousToken, thunkAPI) => {
    if (previousToken) {
      try {
        const response = await fetch(`${apiUrl}/authenticate`, {
          headers: {
            'Authorization': `Bearer ${previousToken}`
          }
        });
        const result = await response.json();
        const { token, error } = result;
        if (token) {
          localStorage.setItem('token', token);
          thunkAPI.dispatch(storeValidToken(token));
        } else if (error) {
          localStorage.removeItem('token');
        }
        return result;
      } catch(error) {
        console.error(`Error logging in: ${error.message}`);
        return { error: error.message };
      }
    }
  }
);

export const authenticatedSlice = createSlice({
  name: 'authenticated',
  initialState: false,
  reducers: {
    loginSuccess() {
      return true;
    },
    logoutConfirmed() {
      return false;
    }
  },
  extraReducers: builder => {
    builder.addCase(
      attemptAuthentication.fulfilled,
      (_, action) => action.payload.token ? true : false
    );
  }
});

export const authenticated = state => state.authenticated;

export const { loginSuccess, logoutConfirmed } = authenticatedSlice.actions;

export default authenticatedSlice.reducer;
