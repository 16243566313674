import { useEffect } from 'react';

import Carousel from 'react-bootstrap/Carousel';
import { useDispatch, useSelector } from 'react-redux';

import { authenticated } from '../../../../redux/features/authenticated/authenticatedSlice';
import {
  choirVibesPhotosInfo,
  getChoirVibesPhotos
} from '../../../../redux/features/choirVibes/choirVibesPhotosSlice';

import ChoirVibesPhoto from './ChoirVibesPhoto';
import Loading from '../../../generic/Loading';

import './ChoirVibes.css';

function ChoirVibes() {

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(authenticated);
  const { isLoading, choirVibesPhotos } = useSelector(choirVibesPhotosInfo);

  useEffect(() => {
    if (!choirVibesPhotos.length) {
      dispatch(getChoirVibesPhotos());
    }
  }, [dispatch, choirVibesPhotos.length]);

  const displaySlides = () => {
    return choirVibesPhotos.map(choirVibesPhoto => {
      return (
        <Carousel.Item key={ choirVibesPhoto.id }>
          <ChoirVibesPhoto
            choirVibesPhoto={ choirVibesPhoto }
          />
        </Carousel.Item>
      )
    })
  };

  const authenticatedClass = isAuthenticated ? ' is-authenticated' : '';

  return (
    <div className={ `media-tab-choir-vibes${authenticatedClass}` }>
      {isLoading
        ? <Loading />
        : <Carousel>
          { displaySlides() }
        </Carousel>
      }
    </div>
  );
}

export default ChoirVibes;
