import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiUrl } from '../../../utilities';

const convertEventsByMonthObjectToArray = (eventsByMonthsObject) => {
  return Object.entries(eventsByMonthsObject).map(
    eventsByMonth => ({
      monthYear: eventsByMonth[0],
      events: eventsByMonth[1]
    })
  );
};

export const getEventsByMonths = createAsyncThunk(
  'app/getEventsByMonths',
  async () => {
    try {
      const response = await fetch(`${apiUrl}/events`);
      const eventsByMonthsObject = await response.json();

      const eventsByMonthsArray =
        convertEventsByMonthObjectToArray(eventsByMonthsObject);

      return { isLoading: false, eventsByMonths: eventsByMonthsArray };
    } catch(error) {
      console.error(`Error retrieving events: ${error.message}`);
    }
  }
);

export const eventsSlice = createSlice({
  name: 'events',

  initialState: {
    isLoading: true,
    eventsByMonths: []
  },

  reducers: {
    setEvents(state, action) {
      const eventsByMonthsObject = action.payload;
      state.eventsByMonths =
        convertEventsByMonthObjectToArray(eventsByMonthsObject);
    },

    removeEvent(state, action) {
      const removedEvent = action.payload;

      let isEmptyMonth = false;

      let updatedEventsByMonths = state.eventsByMonths.map(eventsByMonth => {
        const foundEvent = eventsByMonth.events.find(
          event => event.id === removedEvent.id
        );

        if (foundEvent && eventsByMonth.events.length === 1) {
          isEmptyMonth = true;
          return {};
        }

        return foundEvent
          ? {
            ...eventsByMonth,
            events: eventsByMonth.events.filter(
              event => event.id !== removedEvent.id
            )
          }
          : eventsByMonth;
      });

      if (isEmptyMonth) {
        updatedEventsByMonths = updatedEventsByMonths.filter(
          eventsByMonth => eventsByMonth.monthYear
        );
      }

      state.eventsByMonths = updatedEventsByMonths;
    }
  },

  extraReducers: builder => {
    builder.addCase(
      getEventsByMonths.fulfilled,
      (_, action) => action.payload
    );
  }
});

export const eventsInfo = state => state.events;

export const { setEvents, removeEvent } = eventsSlice.actions;

export default eventsSlice.reducer;
