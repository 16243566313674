import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiUrl, sortByOrder } from '../../../utilities';

export const getChoirVibesPhotos = createAsyncThunk(
  'app/getChoirVibesPhotos',
  async () => {
    try {
      const response = await fetch(`${apiUrl}/choir_vibes_photos`);
      const choirVibesPhotos = await response.json();
      return { isLoading: false, choirVibesPhotos };
    } catch(error) {
      console.error(`Error retrieving choir vibes photos: ${error.message}`);
    }
  }
);

export const choirVibesPhotosSlice = createSlice({
  name: 'choirVibesPhotos',
  initialState: {
    isLoading: true,
    choirVibesPhotos: []
  },
  reducers: {
    addChoirVibesPhoto(state, action) {
      const { newChoirVibesPhoto, orderUpdates } = action.payload;

      if (orderUpdates.length) {
        state.choirVibesPhotos = [...state.choirVibesPhotos, newChoirVibesPhoto]
          .map(choirVibesPhoto => {
            const choirVibesPhotoOrderUpdate = orderUpdates.find(orderUpdate => {
              return choirVibesPhoto.id === orderUpdate.id;
            });

            return choirVibesPhotoOrderUpdate
              ? { ...choirVibesPhoto, ...choirVibesPhotoOrderUpdate }
              : choirVibesPhoto;
          })
          .sort(sortByOrder);
      } else {
        state.choirVibesPhotos = [...state.choirVibesPhotos, newChoirVibesPhoto];
      }
    },
    removeChoirVibesPhoto(state, action) {
      const { removedChoirVibesPhoto, orderUpdates } = action.payload;

      let remainingChoirVibesPhotos = state.choirVibesPhotos.filter(choirVibesPhoto => {
        return choirVibesPhoto.id !== removedChoirVibesPhoto.id;
      });
      if (orderUpdates.length) {
        remainingChoirVibesPhotos = remainingChoirVibesPhotos
          .map(choirVibesPhoto => {
            const choirVibesPhotoOrderUpdate = orderUpdates.find(orderUpdate => {
              return choirVibesPhoto.id === orderUpdate.id;
            });

            return choirVibesPhotoOrderUpdate
              ? { ...choirVibesPhoto, ...choirVibesPhotoOrderUpdate }
              : choirVibesPhoto;
          })
          .sort(sortByOrder);
      }
      state.choirVibesPhotos = remainingChoirVibesPhotos;
    },
    updateChoirVibesPhoto(state, action) {
      const { updatedChoirVibesPhoto, orderUpdates } = action.payload;

      state.choirVibesPhotos = state.choirVibesPhotos
        .map(choirVibesPhoto => {
          const choirVibesPhotoOrderUpdate = orderUpdates.find(orderUpdate => {
            return choirVibesPhoto.id === orderUpdate.id;
          });

          if (choirVibesPhotoOrderUpdate) {
            return { ...choirVibesPhoto, ...choirVibesPhotoOrderUpdate };
          } else if (choirVibesPhoto.id === updatedChoirVibesPhoto.id) {
            return { ...choirVibesPhoto, ...updatedChoirVibesPhoto };
          } else {
            return choirVibesPhoto;
          }
        })
        .sort(sortByOrder);
    },
  },
  extraReducers: builder => {
    builder.addCase(
      getChoirVibesPhotos.fulfilled,
      (_, action) => action.payload
    );
  }
});

export const choirVibesPhotosInfo = state => state.choirVibesPhotos;
export const choirVibesPhotosLength = state => state.choirVibesPhotos.choirVibesPhotos.length;

export const {
  addChoirVibesPhoto,
  removeChoirVibesPhoto,
  updateChoirVibesPhoto
} = choirVibesPhotosSlice.actions;

export default choirVibesPhotosSlice.reducer;
