export const apiUrl = 'https://northfield-choir-backend.herokuapp.com';

export const capitalize = string => {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
};

export const capitalizeEveryWord = string => {
  return string
    .split(' ')
    .map(capitalize)
    .join(' ');
};

export const sortByOrder = (a, b) => a.order - b.order;

export const isValidYear = year => {
  const isYearOnlyFourNumbers = /^\d{4}$/.test(year);
  const isYearNowOrEarlier = year <= new Date().getFullYear();
  return isYearOnlyFourNumbers && isYearNowOrEarlier;
};

export const convertDateTimeToTime = dateToConvert => {
  if (!dateToConvert) return '';

  const dateObject = new Date(dateToConvert);
  let daytime = 'am';
  let hours = dateObject.getHours();
  let minutes = dateObject.getMinutes();

  if (hours >= 12) {
    daytime = 'pm';
  }
  if (hours === 0) {
    hours = 12;
  }
  if (hours > 12) {
    hours = hours - 12;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }

  return `${hours}:${minutes}${daytime}`;
};

export const convertDateTimeToMonthWithDay = dateToConvert => {
  return dateToConvert
    ? new Date(dateToConvert)
      .toLocaleDateString()
      .split('/')
      .slice(0, 2)
      .join('/')
    : '';
};

const convertDateTimeToDay = dateToConvert => {
  return dateToConvert
    ? new Date(dateToConvert)
      .toLocaleDateString()
      .split('/')
      [1]
    : '';
};

export const nth = number => {
  const numberInt = parseInt(number, 10);
  if (numberInt > 3 && numberInt < 21) return 'th';
  switch (numberInt % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
};

export const convertDateTimeToDayWithOrdinal = dateToConvert => {
  if (dateToConvert) {
    const day = convertDateTimeToDay(dateToConvert);
    const ordinal = nth(day);
    return `${day}${ordinal}`;
  }

  return '';
};

export const authFetch = async (url, method, rawBody) => {
  const headers = {
    Authorization: `Bearer ${localStorage.token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  const body = JSON.stringify(rawBody);

  const response = await fetch(url, { headers, method, body });
  return await response.json();
};
