import './PageNotFound.css';

import heroUrl from '../../../assets/images/page-not-found.jpg';

function PageNotFound() {
  return (
    <section
      className="page-not-found"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0)), url("${heroUrl}")`
      }}
    >
      <main>
        We couldn't find that page 🤷
      </main>
    </section>
  );
}

export default PageNotFound;