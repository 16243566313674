import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import { authenticated } from '../../../../redux/features/authenticated/authenticatedSlice';
import { displayModal } from '../../../../redux/features/modal/modalSlice';

import EditButtonWithTooltip from '../../../generic/EditButtonWithTooltip';

import './StaffMember.css';

function StaffMember(props) {

  const { isPreview } = props;
  let { staffMember } = props;

  const outletContext = useOutletContext();
  if (!staffMember && outletContext) {
    staffMember = outletContext.staffMember;
  }

  const dispatch = useDispatch();

  const isAuthenticated = useSelector(authenticated);

  const showEditStaffMemberModal = () => {
    dispatch(displayModal({
      innerComponentName: 'EditStaffMemberForm',
      backgroundInfo: { staffMember }
    }));
  };

  const displayStaffMemberBlurbParagraphs = () => {
    const paragraphs = staffMember.blurb
      .split('\n')
      .filter(string => !!string);
    return paragraphs.map((paragraph, index) => (
      <p className='staff-member-blurb-paragraph' key={ index }>
        { paragraph }
      </p>
    ));
  };

  return (
    <div className={`staff-member-container${isPreview ? ' preview' : ''}`}>
      <div className='staff-member-name-and-edit-container'>
        <h3 className='staff-member-name'>{ staffMember.name }</h3>

        {(isAuthenticated && !isPreview) && (
          <EditButtonWithTooltip
            itemName='staff-member'
            placement='left'
            handleClick={ showEditStaffMemberModal }
          />
        )}
      </div>

      <div className='staff-member-photo-and-blurb-container'>
        <div className='staff-member-photo-container'>
          <img
            className='staff-member-photo'
            src={ staffMember.photo }
            alt={ `${staffMember.title}, ${staffMember.name}` }
          />
        </div>

        <div className='staff-member-blurb-container'>
          { displayStaffMemberBlurbParagraphs() }
        </div>
      </div>
    </div>
  );
}

export default StaffMember;
