import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiUrl,sortByOrder } from '../../../utilities';

export const getSwags = createAsyncThunk(
  'app/getSwags',
  async () => {
    try {
      const response = await fetch(`${apiUrl}/swags`);
      const swags = await response.json();
      return { isLoading: false, swags };
    } catch(error) {
      console.error(`Error retrieving swag: ${error.message}`);
    }
  }
);

export const swagsSlice = createSlice({
  name: 'swags',
  initialState: {
    isLoading: true,
    swags: []
  },
  reducers: {
    addSwag(state, action) {
      const { newSwag, orderUpdates } = action.payload;

      if (orderUpdates.length) {
        state.swags = [...state.swags, newSwag]
          .map(swag => {
            const swagOrderUpdate = orderUpdates.find(orderUpdate => {
              return swag.id === orderUpdate.id;
            });

            return swagOrderUpdate
              ? { ...swag, ...swagOrderUpdate }
              : swag;
          })
          .sort(sortByOrder);
      } else {
        state.swags = [...state.swags, newSwag];
      }
    },
    removeSwag(state, action) {
      const { removedSwag, orderUpdates } = action.payload;

      let remainingSwags = state.swags.filter(swag => {
        return swag.id !== removedSwag.id;
      });
      if (orderUpdates.length) {
        remainingSwags = remainingSwags
          .map(swag => {
            const swagOrderUpdate = orderUpdates.find(orderUpdate => {
              return swag.id === orderUpdate.id;
            });

            return swagOrderUpdate
              ? { ...swag, ...swagOrderUpdate }
              : swag;
          })
          .sort(sortByOrder);
      }
      state.swags = remainingSwags;
    },
    updateSwag(state, action) {
      const { updatedSwag, orderUpdates } = action.payload;

      state.swags = state.swags
        .map(swag => {
          const swagOrderUpdate = orderUpdates.find(orderUpdate => {
            return swag.id === orderUpdate.id;
          });

          if (swagOrderUpdate) {
            return { ...swag, ...swagOrderUpdate };
          } else if (swag.id === updatedSwag.id) {
            return { ...swag, ...updatedSwag };
          } else {
            return swag;
          }
        })
        .sort(sortByOrder);
    },
  },
  extraReducers: builder => {
    builder.addCase(
      getSwags.fulfilled,
      (_, action) => action.payload
    );
  }
});

export const swagInfo = state => state.swags;
export const swagsLength = state => state.swags.swags.length;

export const {
  addSwag,
  removeSwag,
  updateSwag
} = swagsSlice.actions;

export default swagsSlice.reducer;
