import { useEffect } from 'react';

import Calendar from './Calendar';
import EventsList from './EventsList';

import './Events.css';

function Events() {

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <section className='events'>
      <Calendar />
      <EventsList />
    </section>
  );
}

export default Events;
