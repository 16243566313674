import { useEffect } from 'react';

import Support from './Support';
import Swag from './Swag';

import './SwagAndSupport.css';

function SwagAndSupport() {

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <section className='swag-and-support'>
      <Support />
      <Swag />
    </section>
  );
}

export default SwagAndSupport;
