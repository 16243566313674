import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Support.css';

import paypalDonateButton from '../../../../assets/images/paypal-donate-button.png';
import paypalQRCode from '../../../../assets/images/paypal-qr-code.png';
import venmoQRCode from '../../../../assets/images/venmo-qr-code.jpg';

function Support() {

  return (
    <div className='support-container'>
      <h2 className='support-heading'>Support Us</h2>

      <div className='support-header'>
        <p className='support-header-text'>
          Help us raise money <FontAwesomeIcon icon={ faHeart } />
        </p>
      </div>

      <div className='support-options-container'>
        <div className='support-options-text-container'>
          <h3>Support options:</h3>
          <ul>
            <li>Sponsor a student ($20-$60 gets a vocal lesson)</li>
            <li>Sponsor a professional sound engineer ($100)</li>
            <li>Sponsor a videographer ($100)</li>
            <li>Donate to our music library ($150)</li>
            <li>Donate for our accompanist ($7000 per year)</li>
            <li>Any amounts helps!</li>
          </ul>
        </div>

        <h3 className='donate-header'>Donate here:</h3>

        <div className='paypal-container'>
          <div className='paypal-link-container'>
            <a
              href="https://www.paypal.com/donate/?hosted_button_id=2XLPA5TC339G6"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src={ paypalDonateButton }
                alt='paypal link for swag and support'
                className='paypal-button'
              />
            </a>
          </div>

          <p>Our Paypal QR code:</p>
          <div className='paypal-image-container'>
            <img
              src={ paypalQRCode }
              alt='paypal qr code for swag and support'
              className='paypal-code'
            />
          </div>
        </div>

        <div className='venmo-image-container'>
          <p>Our Venmo:</p>
          <img
            src={ venmoQRCode }
            alt='venmo link for swag and support'
            className='venmo-code'
          />
        </div>
      </div>
    </div>
  );
}

export default Support;
