import { useEffect } from 'react';

import Carousel from 'react-bootstrap/Carousel';
import { useDispatch, useSelector } from 'react-redux';

import { authenticated } from '../../../../redux/features/authenticated/authenticatedSlice';
import {
  getHomeCarouselPhotos,
  homeCarouselPhotosInfo
} from '../../../../redux/features/homepage/homeCarouselPhotosSlice';

import Loading from '../../../generic/Loading';

import './HomeCarousel.css';
import HomeCarouselPhoto from './HomeCarouselPhoto';

function HomePhotos() {

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(authenticated);
  const { isLoading, homeCarouselPhotos } = useSelector(homeCarouselPhotosInfo);

  useEffect(() => {
    if (!homeCarouselPhotos.length) {
      dispatch(getHomeCarouselPhotos());
    }
  }, [dispatch, homeCarouselPhotos.length]);

  const displaySlides = () => {
    return homeCarouselPhotos.map(homeCarouselPhoto => {
      return (
        <Carousel.Item key={ homeCarouselPhoto.id }>
          <HomeCarouselPhoto
            homeCarouselPhoto={ homeCarouselPhoto }
          />
        </Carousel.Item>
      )
    })
  };

  const authenticatedClass = isAuthenticated ? ' is-authenticated' : '';

  return (
    <div id='photos' className={ `home-carousel${authenticatedClass}` }>
      {isLoading
        ? <Loading />
        : <Carousel>
          { displaySlides() }
        </Carousel>
      }
    </div>
  );
}

export default HomePhotos;
