import { removeVideo } from '../../../../redux/features/videos/videosSlice';
import { showAppAlert } from '../../../../redux/features/appAlert/appAlertSlice';

import { apiUrl } from '../../../../utilities';

export const handleVideoDelete = async (closeModal, dispatch, details) => {
  const { video } = details;

  const videosUrl = `${apiUrl}/videos/${video.id}`;
  const deleteOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${localStorage.token}`
    }
  };

  try {
    const response = await fetch(videosUrl, deleteOptions);
    if (response.ok) {
      dispatch(showAppAlert({
        title: 'Success',
        message: `${video.title}, ${video.name} removed`,
        bg: 'success'
      }));
      dispatch(removeVideo(video));
      closeModal();

    } else {
        const { error, exception } = await response.json();
        const errorMessage =
          `Error removing ${video.title}, ${video.name}: ${exception || error}`;
        dispatch(showAppAlert({ message: errorMessage }));
    }

  } catch (error) {
    const errorMessage =
      `Error removing ${video.title}, ${video.name}: ${error.message}`;
    dispatch(showAppAlert({ message: errorMessage }));
  }
};
