import { useState } from 'react';

import Button from 'react-bootstrap/Button';
import { faAsterisk, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import { useDispatch } from 'react-redux';

import { showAppAlert } from '../../../../redux/features/appAlert/appAlertSlice';

import { apiUrl } from '../../../../utilities';

import './ContactForm.css';

function ContactForm() {

  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [validated, setValidated] = useState(false);

  const emailBody = {
    from: 'Contact',
    subject: 'Contact Us',
    content: `From: ${firstName} ${lastName}

Email: ${email}

Message:
${message}
    `
  };

  const makeContactRequestOptions = () => ({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email: emailBody })
  });

  const resetForm = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setMessage('');
    setValidated(false);
  };

  const handleSendEmail = async event => {
    event.preventDefault();

    const form = event.target;
    if (!form.checkValidity()) {
      setValidated(true);
      return;
    }

    try {
      const createOptions = makeContactRequestOptions();
      const response = await fetch(`${apiUrl}/email`, createOptions);
      const emailResult = await response.json();

      if (emailResult.status_code === '202') {
        resetForm();
        dispatch(showAppAlert({
          title: 'Success',
          message: 'Your email has been sent!',
          bg: 'success'
        }));
      } else {
        const errorMessage = 'Please try again, our owl did not deliver your email.';
        dispatch(showAppAlert({ message: errorMessage }));
      }

    } catch (error) {
      const errorMessage =
        `Please try again. Owl failed delivering your email because: ${error.message}`;
      dispatch(showAppAlert({ message: errorMessage }));
    }
  };

  const isAnyFieldEmpty = !firstName || !lastName || !email || !message;

  return (
    <div className='contact-form-container' onSubmit={ handleSendEmail }>
      <h3 className='contact-form-header'>Drop us a line</h3>
      <Form noValidate validated={ validated } className='contact-form'>
        <Form.Group className='mb-3' controlId='formFirstName'>
          <Form.Label className='required'>
            First Name<FontAwesomeIcon icon={ faAsterisk } />
          </Form.Label>
          <Form.Control
            required
            type='text'
            placeholder='First name'
            value={ firstName }
            onChange={ event => setFirstName(event.target.value) }
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formLastName'>
          <Form.Label className='required'>
            Last Name<FontAwesomeIcon icon={ faAsterisk } />
          </Form.Label>
          <Form.Control
            required
            type='text'
            placeholder='Last name'
            value={ lastName }
            onChange={ event => setLastName(event.target.value) }
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formEmail'>
          <Form.Label className='required'>
            Email<FontAwesomeIcon icon={ faAsterisk } />
          </Form.Label>
          <Form.Control
            required
            type='email'
            placeholder='your@email.com'
            value={ email }
            onChange={ event => setEmail(event.target.value) }
          />
          <Form.Control.Feedback type="invalid">
            Please provide a valid email.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className='mb-3' controlId='formMessage'>
          <Form.Label className='required'>
            Message<FontAwesomeIcon icon={ faAsterisk } />
          </Form.Label>
          <Form.Control
            required
            type='text'
            as='textarea'
            rows={ 5 }
            placeholder='Send us an email!'
            value={ message }
            onChange={ event => setMessage(event.target.value) }
          />
        </Form.Group>

        <Button
          disabled={ isAnyFieldEmpty }
          variant='primary'
          type='submit'
        >
          Send <FontAwesomeIcon icon={ faPaperPlane } />
        </Button>
      </Form>
    </div>
  );
}

export default ContactForm;
