import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';

import './OrderProcessing.css';

function OrderProcessing({
  size,
  setSize,
  quantity,
  setQuantity,
  validated
}) {

  const createOptions = choices => {
    return choices.map(choice => {
      return (
        <option key={ choice } value={ choice }>
          { choice }
        </option>
      );
    });
  };

  const isSizeNotSelected = size === '';
  const isQuantityNotSelected = quantity === '';

  return (
    <div className='order-form-container'>
      <Form
        noValidate
        validated={ validated }
        className='order-form'
        aria-describedby='orderFormPurchaseNotes'
      >
        <Form.Group className='mb-3' controlId='formSizeSelector'>
          <Form.Label className='required'>
            Size<FontAwesomeIcon icon={ faAsterisk } />
          </Form.Label>
          <Form.Select
            aria-label='swag size selector'
            className={ isSizeNotSelected ? 'not-selected' : '' }
            value={ size }
            onChange={ event => setSize(event.target.value) }
          >
            <option value=''>Please select a size</option>
            { createOptions(['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL']) }
          </Form.Select>
        </Form.Group>

        <Form.Group className='mb-3' controlId='formQuantitySelector'>
          <Form.Label className='required'>
            Quantity<FontAwesomeIcon icon={ faAsterisk } />
          </Form.Label>
          <Form.Select
            aria-label='swag quantity selector'
            className={ isQuantityNotSelected ? 'not-selected' : '' }
            value={ quantity }
            onChange={ event => setQuantity(event.target.value) }
          >
            <option value=''>Please select a quantity</option>
            { createOptions(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']) }
          </Form.Select>
        </Form.Group>
      </Form>
    </div>
  );
}

export default OrderProcessing;
