import { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import './HomeHero.css';

import heroUrl from '../../../../assets/images/Home-Page-Carnegie-Hall-Website.jpg';

function HomeHero() {

  const [titleAnimationClass, setTitleAnimationClass] = useState('');
  const [textLeftTopAnimationClass, setTextLeftTopAnimationClass] = useState('');
  const [textLeftTopStandUp, setTextLeftTopStandUp] = useState('');
  const [textLeftBottomAnimationClass, setTextLeftBottomAnimationClass] = useState('');
  const [textRightTopAnimationClass, setTextRightTopAnimationClass] = useState('');
  const [textRightBottomAnimationClass, setTextRightBottomAnimationClass] = useState('');

  useEffect(() => {
    const timeoutId1 = setTimeout(() => {
      setTitleAnimationClass(' slide-down-and-fade-in');
      setTextLeftTopAnimationClass(' slide-right');
      setTextLeftBottomAnimationClass(' slide-up');
      setTextRightTopAnimationClass(' bounce-up-and-down');
      setTextRightBottomAnimationClass(' slide-up');
    }, 0);
    const timeoutId2 = setTimeout(() => {
      setTextLeftTopStandUp(' stand-up');
    }, 1500);
    return () => {
      clearTimeout(timeoutId1);
      clearTimeout(timeoutId2);
    };
  }, []);

  return (
    <div
      className='home-hero'
      style={{
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0)), url("${heroUrl}")`
      }}
    >
      <div className='title-container'>
        <h2 className={`title${titleAnimationClass}`}>Northfield Choir</h2>
      </div>
      <div className='home-links'>
        <div className='home-left'>
          <Link
            to='choirs'
            role='choirs link'
            className={`home-left-text-top${textLeftTopAnimationClass}${textLeftTopStandUp}`}
          >
            See our choirs!
          </Link>
          <Link
            to='events'
            role='events link'
            className={`home-left-text-bottom${textLeftBottomAnimationClass}`}
          >
            Check out our upcoming events!
          </Link>
        </div>
        <div className='home-right'>
          <Link
            to='media'
            role='media link'
            className={`home-right-text-top${textRightTopAnimationClass}`}
          >
            Watch our performances!
          </Link>
          <Link
            to='swag-&-support'
            role='swag & support link'
            className={`home-right-text-bottom${textRightBottomAnimationClass}`}
          >
            Support us!
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HomeHero;
