import { removeHomeCarouselPhoto } from "../../../../../redux/features/homepage/homeCarouselPhotosSlice";
import { showAppAlert } from '../../../../../redux/features/appAlert/appAlertSlice';

import { apiUrl } from '../../../../../utilities';

import {
  storage,
  ref,
  deleteObject
} from "../../../../../firebase/firebase";

export const handleHomeCarouselPhotoDelete = async (closeModal, dispatch, details) => {
  const { homeCarouselPhoto } = details;

  const firebaseStorageUrl = `/homepage/home_carousel_photos/${homeCarouselPhoto.description}`;
  const firebaseImageRef = ref(storage, firebaseStorageUrl);

  const homeCarouselPhotoUrl = `${apiUrl}/home_carousel_photos/${homeCarouselPhoto.id}`;
  const deleteOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${localStorage.token}`
    }
  };

  try {
    await deleteObject(firebaseImageRef);
    const response = await fetch(homeCarouselPhotoUrl, deleteOptions);
    const { order_updates: orderUpdates } = await response.json();

    if (response.ok) {
      dispatch(showAppAlert({
        title: 'Success',
        message: `${homeCarouselPhoto.description} removed`,
        bg: 'success'
      }));
      dispatch( removeHomeCarouselPhoto({ removedHomeCarouselPhoto: homeCarouselPhoto, orderUpdates }));
      closeModal();

    } else {
        const { error, exception } = await response.json();
        const errorMessage =
          `Error removing ${homeCarouselPhoto.description}: ${exception || error}`;
        dispatch(showAppAlert({ message: errorMessage }));
    }

  } catch (error) {
    const errorMessage = `Error removing ${homeCarouselPhoto.description}: ${error.message}`;
    dispatch(showAppAlert({ message: errorMessage }));
  }
};
