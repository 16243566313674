import Button from 'react-bootstrap/Button';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useDispatch, useSelector } from 'react-redux';

import {
  authenticated,
  logoutConfirmed
} from '../../redux/features/authenticated/authenticatedSlice';
import { displayModal } from '../../redux/features/modal/modalSlice';
import { removeToken } from '../../redux/features/login/loginSlice';

import CartButton from './Cart/CartButton';
import NCNav from './NCNav';

import './Header.css';

import instagramLogo from '../../assets/images/Instagram_logo_2016.svg.png';
import logoUrl from '../../assets/images/logo-2021.jpg';

export const handleLogoutConfirm = (closeModal, dispatch) => {
  dispatch(logoutConfirmed());
  dispatch(removeToken());
  localStorage.removeItem('token');
  closeModal();
};

function Header() {
  const isMobile = window.matchMedia('(max-device-width: 1200px)').matches;

  const isAuthenticated = useSelector(authenticated);

  const dispatch = useDispatch();

  const runLogout = () => {
    dispatch(displayModal({
      innerComponentName: 'Confirm',
      backgroundInfo: {
        handleConfirmName: 'handleLogoutConfirm',
        message: 'Logout?'
      }
    }));
  };

  const renderTooltip = (props) => (
    <Tooltip id='home-page-link-tooltip' {...props}>
      Home
    </Tooltip>
  );

  return (
    <Navbar
      expand='md'
      fixed='top'
      role='main navigation'
      collapseOnSelect={ true }
    >
      <Navbar.Toggle
        aria-controls='basic-navbar-nav'
        className='navbar-dark'
      />

        
      <Nav.Link
        as={ Link }
        to='/'
        id='logo-link'
        eventKey='1'
        role='homepage link'
      >
        {isMobile
          ? <img id='logo' src={ logoUrl } alt='northfield choir logo' />
          : <OverlayTrigger
            placement='right'
            delay={{ show: 50, hide: 150 }}
            overlay={ renderTooltip }
          >
            <img id='logo' src={ logoUrl } alt='northfield choir logo' />
          </OverlayTrigger>
        }
      </Nav.Link>

      <NCNav />

      <a
        href='https://www.instagram.com/nighthawk_choir22/'
        className='instagram-link'
        target='_blank'
        rel='noopener noreferrer'
      >
        <img
          className='instagram-logo'
          src={ instagramLogo }
          alt='instagram-logo'
        />
      </a>

      <CartButton />

      {isAuthenticated && (
        <Navbar.Brand className='logout-text'>
          <Button variant='outline-danger' onClick={ runLogout }>
            Logout <FontAwesomeIcon icon={ faSignOutAlt } />
          </Button>
        </Navbar.Brand>
      )}

    </Navbar>
  );
}

export default Header;
