import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText, faPhone } from '@fortawesome/free-solid-svg-icons';

import ContactForm from '../ContactForm';

import './ContactInfo.css';

function ContactInfo({ staffMembers }) {

  const staffMember = staffMembers.find(
    staffMember => staffMember.title === 'Director'
  );

  return (
    <div className='contact-info-container'>
      <div className='contact-image-and-address-container'>
        <div className='contact-image-container'>
          <img
            className='staff-member-contact-photo'
            src={ staffMember.photo }
            alt={ `${staffMember.title}, ${staffMember.name}` }
          />
          <h3 className='staff-member-contact-photo-details'>
            {`${staffMember.title}, ${staffMember.name}`}
          </h3>
        </div>
        <address>
          <h3 className='address-header'>We'd love to hear from you!</h3>
          <a
            className="phone"
            href="tel: +1-720-423-8199"
          >
            <FontAwesomeIcon icon={ faPhone } />
            720-423-8199
          </a>
          <a
            className="email"
            href="mailto: matthew_steele@dpsk12.net"
          >
            <FontAwesomeIcon icon={ faEnvelopeOpenText } />
            matthew_steele@dpsk12.net
          </a>
        </address>
      </div>
      <ContactForm />
    </div>
  );
}

export default ContactInfo;
