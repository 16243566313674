import { useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import { authenticated } from '../../../../redux/features/authenticated/authenticatedSlice';
import {
  getVideos,
  videosInfo
} from '../../../../redux/features/videos/videosSlice';
import { showAppAlert } from '../../../../redux/features/appAlert/appAlertSlice';
import { updateChoirAttribute } from '../../../../redux/features/choirs/choirsSlice';

import ChoristersContainer from '../ChoristersContainer';
import EditButtonWithTooltip from '../../../generic/EditButtonWithTooltip';
import Loading from '../../../generic/Loading';
import VideoPlayer from '../../Media/VideoPlayer';

import { apiUrl, authFetch, capitalize } from '../../../../utilities';

import './Choir.css';

function Choir() {

  const { choir, videoPlayingId, setVideoPlayingId } = useOutletContext();

  const dispatch = useDispatch();
  const { isLoading, videos } = useSelector(videosInfo);
  const isAuthenticated = useSelector(authenticated);

  const [blurb, setBlurb] = useState(choir.blurb);
  const [editBlurb, setEditBlurb] = useState(false);

  const choirVideo = videos.find(video => video.id === choir.video_id);

  useEffect(() => {
    if (!videos.length) {
      dispatch(getVideos());
    }
  }, [dispatch, videos.length]);

  const handleUpdatedChoirResponse = (updatedChoir, attribute, newValue) => {
    if (updatedChoir.id) {
      dispatch(updateChoirAttribute({
        attribute,
        newValue,
        choirId: choir.id,
      }));

      const attributeDisplay = capitalize(attribute).replace('_id', '');
      dispatch(showAppAlert({
        title: 'Success',
        message: `${attributeDisplay} updated for ${updatedChoir.name}.`,
        bg: 'success'
      }));
    } else {
      const errorMessage = updatedChoir.errors
        .reduce((errorString, error, index) => {
          return errorString += `${index + 1}. ${error}\n`;
        }, '');
      throw new Error(errorMessage);
    }
  };

  const updateVideo = async event => {
    const newVideoId = parseInt(event.target.value, 10);

    try {
      const updatedChoir = await authFetch(
        `${apiUrl}/choirs/${choir.id}`,
        'PATCH',
        { choir: { video_id: newVideoId } }
      );

      handleUpdatedChoirResponse(updatedChoir, 'video_id', newVideoId);

    } catch (error) {
      dispatch(showAppAlert({ message: error.message }));
    }
  };

  const cancelUpdateBlurb = () => {
    setEditBlurb(false);
    setBlurb(choir.blurb);
  };

  const updateBlurb = async () => {
    try {
      const updatedChoir = await authFetch(
        `${apiUrl}/choirs/${choir.id}`,
        'PATCH',
        { choir: { blurb } }
      );

      handleUpdatedChoirResponse(updatedChoir, 'blurb', blurb);
      if (updatedChoir.id) setEditBlurb(false);

    } catch (error) {
      dispatch(showAppAlert({ message: error.message }));
    }
  };

  const renderVideoOptions = () => {
    return videos.map(video => {
      return (
        <option key={ video.id } value={ video.id }>
          { video.title } - { video.name }, { video.year }
        </option>
      );
    });
  };

  return (
    <div className='choir-container'>
      <div className='choir-blurb-and-edit-container'>
        {editBlurb
          ? <Form.Group className='mb-3' controlId={`form${choir.name}Blurb`}>
            <Form.Label visuallyHidden>Edit Blurb</Form.Label>
            <Form.Control
              isInvalid={ !blurb }
              type='text'
              as='textarea'
              rows={ 3 }
              placeholder='Blurb'
              value={ blurb }
              onChange={ event => setBlurb(event.target.value) }
            />
            <div className='choir-blurb-edit-container-buttons'>
              <Button
                variant='secondary'
                type='submit'
                onClick={ cancelUpdateBlurb }
              >
                Cancel <FontAwesomeIcon icon={ faTimes } />
              </Button>
              <Button
                disabled={ !blurb }
                variant='primary'
                type='submit'
                onClick={ updateBlurb }
              >
                Save <FontAwesomeIcon icon={ faSave } />
              </Button>
            </div>
          </Form.Group>
          : <p className='choir-blurb'>{ choir.blurb }</p>
        }

        <EditButtonWithTooltip
          isAdminOnly
          itemName='choir-blurb'
          placement='left'
          handleClick={ () => setEditBlurb(!editBlurb) }
        />
      </div>

      {(!!choir.choristers.length || isAuthenticated) && (
        <ChoristersContainer choir={ choir } />
      )}

      { isLoading && <Loading /> }
      {choirVideo && (
        <VideoPlayer
          key={ choirVideo.id }
          video={ choirVideo }
          videoPlayingId={ videoPlayingId }
          setVideoPlayingId={ setVideoPlayingId }
          isChoir
        />
      )}
      {isAuthenticated && choirVideo && (
        <Form.Group className="mb-3" controlId={`form${choir.name}VideoSelect`}>
          <Form.Label className='choir-video-select-label'>
            Choose a different video
          </Form.Label>
          <Form.Select
            aria-label={`${choir.name} choir video select`}
            onChange={ updateVideo }
            value={ choirVideo.id }
          >
            <option>Choose different video</option>
            { renderVideoOptions() }
          </Form.Select>
        </Form.Group>
      )}
    </div>
  );
}

export default Choir;
