import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import './ChooseResource.css';

function ChooseResource({ closeModal }) {

  return (
    <>
      <Modal.Title>Choose Resource</Modal.Title>
      <Modal.Body>
        <div className='resource-links'>
          <a
            href='https://schoology.dpsk12.org/course/5522273243/materials?f=547596368'
            className='resource-link'
            target='_blank'
            rel='noopener noreferrer'
          >Treble Hawks</a>
          <a
            href='https://schoology.dpsk12.org/course/5522273242/materials?f=547537551'
            className='resource-link'
            target='_blank'
            rel='noopener noreferrer'
          >Flight Crew</a>
          <a
            href='https://schoology.dpsk12.org/course/5522273240/materials'
            className='resource-link'
            target='_blank'
            rel='noopener noreferrer'
          >Nighthawk Honors</a>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={ closeModal }>
          Close
        </Button>
      </Modal.Footer>
    </>
  );
}

export default ChooseResource;
