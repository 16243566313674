import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { apiUrl } from '../../../utilities';

export const getHomeGalleryPhotos = createAsyncThunk(
  'app/getHomeGalleryPhotos',
  async () => {
    try {
      const response = await fetch(`${apiUrl}/home_gallery_photos`);
      const homeGalleryPhotos = await response.json();
      return { isLoading: false, homeGalleryPhotos };
    } catch(error) {
      console.error(`Error retrieving home gallery photos: ${error.message}`);
    }
  }
);

export const homeGalleryPhotosSlice = createSlice({
  name: 'homeGalleryPhotos',
  initialState: {
    isLoading: true,
    homeGalleryPhotos: []
  },
  reducers: {
    updateHomeGalleryPhotoAttribute(state, action) {
      const { homeGalleryPhotoId, attribute, newValue } = action.payload;
      state.homeGalleryPhotos = state.homeGalleryPhotos.map(homeGalleryPhoto => {
        return homeGalleryPhoto.id === homeGalleryPhotoId
          ? { ...homeGalleryPhoto, [attribute]: newValue }
          : homeGalleryPhoto;
      });
    }
  },
  extraReducers: builder => {
    builder.addCase(
      getHomeGalleryPhotos.fulfilled,
      (_, action) => action.payload
    );
  }
});

export const homeGalleryPhotosInfo = state => state.homeGalleryPhotos;

export const { updateHomeGalleryPhotoAttribute } = homeGalleryPhotosSlice.actions;

export default homeGalleryPhotosSlice.reducer;
