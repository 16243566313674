import { useState } from 'react';

import Button from 'react-bootstrap/Button';
import { faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';

import { addCartItem } from '../../../../redux/features/cart/cartSlice';
import { modalBackgroundInfo } from '../../../../redux/features/modal/modalSlice';
import { showAppAlert } from '../../../../redux/features/appAlert/appAlertSlice';

import OrderProcessing from '../OrderProcessing';

import { capitalize } from '../../../../utilities';

import './OrderSwag.css';

function OrderSwag({ closeModal }) {

  const dispatch = useDispatch();

  const [size, setSize] = useState('');
  const [quantity, setQuantity] = useState('');

  const [validated, setValidated] = useState(false);

  const { swag } = useSelector(modalBackgroundInfo);
  const imageName = `${swag.group} ${swag.category}`;
  const swagCategoryCapitalized = capitalize(swag.category);

  const resetForm = () => {
    setSize('');
    setQuantity('');
    setValidated(false);
  };

  const handleAddToCart = event => {
    event.preventDefault();

    const form = event.target.closest('.modal-footer').previousSibling.lastChild.firstChild;
    if (!form.checkValidity()) {
      setValidated(true);
      return;
    }

    dispatch(addCartItem({
      ...swag,
      size,
      quantity: parseInt(quantity, 10)
    }));

    resetForm();

    dispatch(showAppAlert({
      title: 'Success',
      message: `${swag.group} ${swag.category}, ${quantity} ${size}, added to cart!`,
      bg: 'success'
    }));

    closeModal();
  };

  const isAnyFieldEmpty = !size || !quantity;

  return (
    <section className='order-swag-modal'>
      <Modal.Title>Order { swagCategoryCapitalized }</Modal.Title>
      <Modal.Body>
        <div className='swag-item'>
          <h3 className='swag-group'>{ swag.group }</h3>
          <img
            className='swag-image'
            src={ swag.image }
            alt={ imageName }
          />
        </div>
        <OrderProcessing
          size={ size }
          setSize={ setSize }
          quantity={ quantity }
          setQuantity={ setQuantity }
          validated={ validated }
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={ closeModal }>
          Cancel
        </Button>
        <Button
          disabled={ isAnyFieldEmpty }
          variant='primary'
          onClick={ handleAddToCart }
        >
          Add to Cart <FontAwesomeIcon icon={ faCartShopping } />
        </Button>
      </Modal.Footer>
    </section>
  );
}

export default OrderSwag;
