import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  getVideos,
  videosInfo
} from '../../../../redux/features/videos/videosSlice';

import Loading from '../../../generic/Loading';
import VideosTab from './VideosTab';

import './Videos.css';

function Videos() {

  const dispatch = useDispatch();
  const { isLoading, videos } = useSelector(videosInfo);

  useEffect(() => {
    if (!videos.length) {
      dispatch(getVideos());
    }
  }, [dispatch, videos.length]);

  return (
    <>
      {isLoading
        ? <Loading />
        : <VideosTab videos={ videos } />
      }
    </>
  );
}

export default Videos;
