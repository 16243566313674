import Button from 'react-bootstrap/Button';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useSelector } from 'react-redux';

import { authenticated } from '../../../redux/features/authenticated/authenticatedSlice';

import './DeleteButtonWithTooltip.css';

function DeleteButtonWithTooltip({
  isAdminOnly,
  itemName,
  tooltipText,
  placement,
  handleClick
}) {

  const isAuthenticated = useSelector(authenticated);

  const renderDeleteTooltip = props => (
    <Tooltip id={ `${itemName}-delete-button-tooltip` } {...props}>
      { tooltipText || 'Remove' }
    </Tooltip>
  );

  return (
    <>
      {!isAdminOnly || isAuthenticated
        ? (
          <OverlayTrigger
          placement={ placement }
          delay={{ show: 50, hide: 150 }}
          overlay={ renderDeleteTooltip }
          >
            <Button
              className={ `delete-trash-icon ${itemName}-delete-button` }
              variant='danger'
              onClick={ handleClick }
            >
              <FontAwesomeIcon icon={ faTrashAlt } />
            </Button>
          </OverlayTrigger>
        )
        : null
      }
    </>
  );
}

export default DeleteButtonWithTooltip;
