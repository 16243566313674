import Button from 'react-bootstrap/Button';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';

import { authenticated } from '../../../../../redux/features/authenticated/authenticatedSlice';
import { displayModal } from '../../../../../redux/features/modal/modalSlice';

import DeleteButtonWithTooltip from '../../../../generic/DeleteButtonWithTooltip';
import EditButtonWithTooltip from '../../../../generic/EditButtonWithTooltip';

import './HomeCarouselPhoto.css';

function HomeCarouselPhoto({ homeCarouselPhoto }) {

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(authenticated);

  const showAddOrEditHomeCarouselPhotoModal = isAdd => {
    const backgroundInfo = isAdd ? {} : { backgroundInfo: { homeCarouselPhoto } };

    dispatch(displayModal({
      innerComponentName: 'AddOrEditHomeCarouselPhotoForm',
      ...backgroundInfo
    }));
  };

  const confirmHomeCarouselPhotoDelete = () => {
    dispatch(displayModal({
      innerComponentName: 'Confirm',
      backgroundInfo: {
        handleConfirmName: 'handleHomeCarouselPhotoDelete',
        isDelete: true,
        message: `Remove photo #${homeCarouselPhoto.order} "${homeCarouselPhoto.description}" permanently?`,
        details: {
          homeCarouselPhoto
        },
        displayImage: {
          src: homeCarouselPhoto.photo,
          alt: homeCarouselPhoto.description,
        }
      }
    }));
  };

  return (
    <>
      <img
        className='d-block w-100 home-carousel-photo-image'
        src={ homeCarouselPhoto.photo }
        alt={ homeCarouselPhoto.description }
      />

      <p className='home-carousel-photo-description'>
        { homeCarouselPhoto.description }
      </p>

      {isAuthenticated && (
        <div className='home-carousel-photo-action-buttons'>
          <Button
            className='home-carousel-photo-add-button'
            variant='outline-primary'
            onClick={ () => showAddOrEditHomeCarouselPhotoModal(true) }
          >
            <FontAwesomeIcon icon={ faPlus } />
          </Button>

          <EditButtonWithTooltip
            itemName='home-carousel-photo'
            tooltipText='Update photo'
            placement='top'
            handleClick={ () => showAddOrEditHomeCarouselPhotoModal(false) }
          />

          <DeleteButtonWithTooltip
            itemName='home-carousel-photo'
            tooltipText='Remove photo'
            placement='top'
            handleClick={ confirmHomeCarouselPhotoDelete }
          />
        </div>
      )}
    </>
  );
}

export default HomeCarouselPhoto;
