import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';

import { authenticated } from '../../../../redux/features/authenticated/authenticatedSlice';
import { displayModal } from '../../../../redux/features/modal/modalSlice';

import DeleteButtonWithTooltip from '../../../generic/DeleteButtonWithTooltip';
import EditButtonWithTooltip from '../../../generic/EditButtonWithTooltip';

import './SwagItem.css';

function SwagItem({ swag, isPreview }) {
  const imageName = `${swag.group} ${swag.category}`;

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(authenticated);

  const showEditSwagItemModal = () => {
    dispatch(displayModal({
      innerComponentName: 'AddOrEditSwagItemForm',
      backgroundInfo: { swag }
    }));
  };

  const confirmSwagItemDelete = () => {
    dispatch(displayModal({
      innerComponentName: 'Confirm',
      backgroundInfo: {
        handleConfirmName: 'handleSwagItemDelete',
        isDelete: true,
        message: `Remove ${imageName} permanently?`,
        details: {
          swag
        },
        displayImage: {
          src: swag.image,
          alt: imageName,
        }
      }
    }));
  };

  const openOrderSwagModal = () => {
    const innerComponentName = 'OrderSwag';
    const backgroundInfo = { swag };
    dispatch(displayModal({ innerComponentName, backgroundInfo }));
  };

  return (
    <div className='swag-item' key={ swag.id }>
      <h3 className='swag-group'>
        { swag.group }{' '}
        <p className='swag-category'>{ swag.category }</p>
      </h3>

      <div className='swag-order-and-action-buttons'>
        {!isPreview && (
          <Button
            className='swag-order-button'
            variant='outline-warning'
            onClick={ openOrderSwagModal }
          >
            Order
          </Button>
        )}

        {isAuthenticated && !isPreview && (
          <div className='swag-action-buttons'>
            <EditButtonWithTooltip
              itemName='swag-item'
              placement='top'
              handleClick={ showEditSwagItemModal }
            />

            <DeleteButtonWithTooltip
              itemName='swag-item'
              placement='top'
              handleClick={ confirmSwagItemDelete }
            />
          </div>
        )}
      </div>

      <img
        className='swag-image'
        src={ swag.image }
        alt={ imageName }
      />
    </div>
  );
}

export default SwagItem;
