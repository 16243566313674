import { useState } from 'react';

import Button from 'react-bootstrap/Button';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import { useDispatch } from 'react-redux';

import { setEvents } from '../../../../redux/features/events/eventsSlice';
import { showAppAlert } from '../../../../redux/features/appAlert/appAlertSlice';

import EventsByMonth from '../EventsByMonth';

import { apiUrl } from '../../../../utilities';

import './AddEventForm.css';

function AddEventForm({ closeModal }) {

  const dispatch = useDispatch();

  const [busy, setBusy] = useState(false);

  const now = new Date();
  const nowMonth = now.getMonth() + 1;
  const nowMonthFormatted = nowMonth < 10 ? `0${nowMonth}` : nowMonth;
  const nowYearMonthDate =
    `${now.getFullYear()}-${nowMonthFormatted}-${now.getDate()}`;

  const [title, setTitle] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [date, setDate] = useState(nowYearMonthDate);

  const startDateTime = (date && startTime)
    ? new Date(`${date} ${startTime}`)
    : '';
  const endDateTime = (date && endTime)
    ? new Date(`${date} ${endTime}`)
    : '';

  const event = {
    title,
    start_date_time: startDateTime,
    end_date_time: endDateTime
  };

  const makeNewEventRequestOptions = () => ({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ event })
  });

  const saveFailed = errorMessage => {
    dispatch(showAppAlert({ message: errorMessage }));
    setBusy(false);
  };

  const handleSaveNewEvent = async event => {
    event.preventDefault();

    setBusy(true);

    try {
      const createOptions = makeNewEventRequestOptions();
      const response = await fetch(`${apiUrl}/events`, createOptions);
      const {
        new_event: newEvent,
        events_by_months: eventsByMonthsObject
      } = await response.json();

      if (newEvent.id) {
        dispatch(setEvents(eventsByMonthsObject));
        dispatch(showAppAlert({
          title: 'Success',
          message: `${newEvent.title} added`,
          bg: 'success'
        }));
        closeModal();
      } else {
        const errorMessage = newEvent.errors
          .reduce((errorString, error, index) => {
            return errorString += `${index + 1}. ${error}\n`;
          }, '');
        saveFailed(errorMessage);
      }

    } catch (error) {
      saveFailed(error.message);
    }
  };

  const monthFullString = date
    ? new Date(date).toLocaleString('default', { month: 'long', timeZone: 'UTC' })
    : '';
  const year = date
    ? new Date(date).getUTCFullYear()
    : '';

  const eventsByMonth = {
    monthYear: `${monthFullString} ${year}`,
    events: [{
      ...event,
      id: -1
    }]
  };

  const isStartTimeBeforeEndTime =
    parseInt(startTime.replace(':', '')) < parseInt(endTime.replace(':', ''));
  const isDateTodayOrLater = endDateTime >= now;

  const isAnyFieldInvalid =
    !title ||
    !(startDateTime instanceof Date) ||
    !(endDateTime instanceof Date) ||
    !isStartTimeBeforeEndTime ||
    !isDateTodayOrLater;

  return (
    <section className='add-event-form-container'>
      <EventsByMonth
        eventsByMonth={ eventsByMonth }
        isPreview
      />

      <Form className='add-event-form'>
        <Form.Group className='mb-3' controlId='formTitle'>
          <Form.Label>Title</Form.Label>
          <Form.Control
            isInvalid={ !title }
            isValid={ title }
            type='text'
            placeholder='Title'
            value={ title }
            onChange={ event => setTitle(event.target.value) }
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formStartTime'>
          <Form.Label>Start Time</Form.Label>
          <Form.Control
            isInvalid={ !startTime }
            isValid={ startTime }
            type='time'
            pattern='[0-9]{2}:[0-9]{2}'
            value={ startTime }
            onChange={ event => setStartTime(event.target.value) }
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formEndTime'>
          <Form.Label>End Time</Form.Label>
          <Form.Control
            isInvalid={ !endTime || !isStartTimeBeforeEndTime }
            isValid={ endTime && isStartTimeBeforeEndTime }
            type='time'
            pattern='[0-9]{2}:[0-9]{2}'
            value={ endTime }
            onChange={ event => setEndTime(event.target.value) }
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formDate'>
          <Form.Label>Date</Form.Label>
          <Form.Control
            isInvalid={ !date || !isDateTodayOrLater }
            isValid={ date && isDateTodayOrLater }
            type='date'
            min={ nowYearMonthDate }
            value={ date }
            onChange={ event => setDate(event.target.value) }
          />
        </Form.Group>

        <Button
          disabled={ isAnyFieldInvalid || busy }
          variant='primary'
          type='submit'
          onClick={ handleSaveNewEvent }
        >
          Save <FontAwesomeIcon icon={ faSave } />
        </Button>
      </Form>
    </section>
  );
}

export default AddEventForm;
