import { useEffect, useState } from 'react';

import Button from 'react-bootstrap/Button';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import imageCompression from 'browser-image-compression';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useDispatch, useSelector } from 'react-redux';

import { modalBackgroundInfo } from '../../../../../redux/features/modal/modalSlice';
import { showAppAlert } from '../../../../../redux/features/appAlert/appAlertSlice';
import {
  addHomeCarouselPhoto,
  updateHomeCarouselPhoto,
  homeCarouselPhotosLength
} from '../../../../../redux/features/homepage/homeCarouselPhotosSlice';

import { apiUrl, nth } from '../../../../../utilities';

import {
  storage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject
} from '../../../../../firebase/firebase';

import './AddOrEditHomeCarouselPhotoForm.css';

function AddOrEditHomeCarouselPhotoForm({ closeModal }) {

  const dispatch = useDispatch();

  const { homeCarouselPhoto = {} } = useSelector(modalBackgroundInfo);
  const isExistingPhoto = !!homeCarouselPhoto.id;

  const numberOfHomeCarouselPhotos = useSelector(homeCarouselPhotosLength);

  const [busy, setBusy] = useState(false);

  const [imageFile, setImageFile] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [fileProgress, setFileProgress] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);

  const [imageUrlPreview, setImageUrlPreview] = useState(homeCarouselPhoto.photo || '');

  const [description, setDescription] = useState(homeCarouselPhoto.description || '');
  const [order, setOrder] = useState(
    homeCarouselPhoto.order || (numberOfHomeCarouselPhotos + 1)
  );

  const homeCarouselPhotoUpdates = {
    photo: imageUrlPreview,
    description,
    order
  };

  const clearImage = () => {
    setImageFile('');
    setImageUrlPreview('');
  };

  const startProcessing = () => {
    setBusy(true);
    setIsProcessing(true);
  };

  const resetProcessing = () => {
    setBusy(false);
    setIsProcessing(false);
    setFileProgress(0);
  };

  const processingFailed = errorMessage => {
    dispatch(showAppAlert({ message: errorMessage }));
    resetProcessing();
  };

  const handleCompressionProgress = percentComplete => {
    setFileProgress(percentComplete);
    if (percentComplete === 100) resetProcessing();
  };

  const handleImageFile = async event => {
    const file = event.target.files[0];
    if (file instanceof File && file?.type.includes('image')) {
      const compressionOptions = {
        maxSizeMB: 0.3,
        maxIteration: 50,
        onProgress: handleCompressionProgress,
        useWebWorker: true
      };
      try {
        startProcessing();
        const compressedFile = await imageCompression(file, compressionOptions);

        setImageFile(compressedFile);
        const urlCreator = window.URL || window.webkitURL;
        setImageUrlPreview(urlCreator.createObjectURL(compressedFile));
      } catch (error) {
        processingFailed(
          `Please try again. Error compressing image: ${error.message}`
        );
      }
    } else if (file) {
      clearImage();
      dispatch(showAppAlert({
        message: `Not an image, the file is a ${file.type}.`
      }));
    } else {
      clearImage();
      dispatch(showAppAlert({ message: 'Please select a valid file.' }));
    }
  };

  const handleUploadStateChange = snapshot => {
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    setFileProgress(progress);

    switch (snapshot.state) {
      case 'paused':
        dispatch(showAppAlert({ message: 'Upload is paused' }));
        break;
      case 'running':
        break;
      default:
        break;
    }
  };

  const handleUploadError = (error, reject) => {
    const errorMessage = `Error uploading image: ${error.message}`;
    reject({ message: errorMessage });
  };

  const handleUploadComplete = async (uploadTask, resolve, reject) => {
    try {
      const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
      resolve(downloadUrl);
    } catch (error) {
      const errorMessage =
        `Please try again. Error retrieving image url: ${error.message}`;
      reject({ message: errorMessage });
    }
  };

  const handleFireBaseUpload = async () => {
    if (isExistingPhoto) {
      const firebaseDeleteStorageUrl = `/homepage/home_carousel_photos/${homeCarouselPhoto.description}`;
      const existingImageRef = ref(storage, firebaseDeleteStorageUrl);
      await deleteObject(existingImageRef);
    }

    const firebaseUploadStorageUrl = `/homepage/home_carousel_photos/${description}`;
    const storageRef = ref(storage, firebaseUploadStorageUrl);
    const uploadTask = uploadBytesResumable(storageRef, imageFile);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        'state_changed', 
        handleUploadStateChange,
        (error) => handleUploadError(error, reject),
        () => handleUploadComplete(uploadTask, resolve, reject)
      );
    });
  }

  const makeSaveHomeCarouselPhotoRequestOptions = firebaseImageUrl => ({
    method: isExistingPhoto ? 'PATCH' : 'POST',
    headers: {
      Authorization: `Bearer ${localStorage.token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      ...homeCarouselPhotoUpdates,
      photo: firebaseImageUrl
    })
  });

  const isImageChosen = imageUrlPreview !== homeCarouselPhoto.photo;
  const isDescriptionChange = description !== homeCarouselPhoto.description;

  const handleUpdateHomeCarouselPhoto = async event => {
    event.preventDefault();

    startProcessing();

    try {
      let firebaseImageUrl = homeCarouselPhoto.photo;
      if (isImageChosen || isDescriptionChange) {
        firebaseImageUrl = await handleFireBaseUpload();
      }

      const homeCarouselPhotoUrl = `${apiUrl}/home_carousel_photos` +
      (isExistingPhoto ? `/${homeCarouselPhoto.id}` : '');
      const saveOptions = makeSaveHomeCarouselPhotoRequestOptions(firebaseImageUrl);
      const response = await fetch(
        homeCarouselPhotoUrl,
        saveOptions
      );
      const {
        home_carousel_photo: savedHomeCarouselPhoto,
        order_updates: orderUpdates
      } = await response.json();

      if (savedHomeCarouselPhoto.id) {
        isExistingPhoto
          ? dispatch( updateHomeCarouselPhoto({ updatedHomeCarouselPhoto: savedHomeCarouselPhoto, orderUpdates }))
          : dispatch( addHomeCarouselPhoto({ newHomeCarouselPhoto: savedHomeCarouselPhoto, orderUpdates }));
        dispatch(showAppAlert({
          title: 'Success',
          message: `Photo, #${savedHomeCarouselPhoto.order} "${savedHomeCarouselPhoto.description}", ${isExistingPhoto ? 'updated' : 'added'}`,
          bg: 'success'
        }));
        setTimeoutId(setTimeout(closeModal, 200));
      } else {
        const errorMessage = savedHomeCarouselPhoto.errors
          .reduce((errorString, error, index) => {
            return errorString += `${index + 1}. ${error}\n`;
          }, '');
        processingFailed(errorMessage);
      }

    } catch (error) {
      setTimeoutId(setTimeout(() => processingFailed(error.message), 200));
    }
  };

  useEffect(() => {
    const removeTimeout = () => timeoutId && clearTimeout(timeoutId);
    return removeTimeout;
  }, [timeoutId]);

  const isAnyFieldEmpty = !imageUrlPreview ||
    !description ||
    !order;
  const maxPosition = isExistingPhoto ? numberOfHomeCarouselPhotos : (numberOfHomeCarouselPhotos + 1);
  const isOrderOneToMaxPosition = order >= 1 && order <= maxPosition;
  const isDescriptionChangedAndImageNotChosen = isDescriptionChange && !isImageChosen;

  const isAnyFieldInvalid = isAnyFieldEmpty ||
    !isOrderOneToMaxPosition ||
    isDescriptionChangedAndImageNotChosen;

  return (
    <section className='add-or-edit-home-carousel-photo-form-container'>
      <h2 className='home-carousel-photo-number'>
        { order }{ nth(order) } Position
      </h2>

      <div className='home-carousel-photo-image-container'>
        <img
          className='home-carousel-photo-image'
          src={ imageUrlPreview }
          alt={ description }
        />
      </div>

      <p className='home-carousel-photo-description'>
        { description }
      </p>

      <Form className='add-or-edit-home-carousel-photo-form'>
        <Form.Group className='mb-3' controlId='formOrder'>
          <Form.Label>Position</Form.Label>
          <Form.Control
            isInvalid={ !order || !isOrderOneToMaxPosition }
            isValid={ order && isOrderOneToMaxPosition }
            type='number'
            min='1'
            max={ maxPosition }
            placeholder='Position'
            value={ order }
            onChange={ event => setOrder(event.target.value) }
            onWheel={ event => event.target.blur() }
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='formPhotoUpload'>
          <Form.Label>Select Photo</Form.Label>
          <Form.Control
            isInvalid={ !imageUrlPreview || isDescriptionChangedAndImageNotChosen }
            isValid={ imageUrlPreview || !isDescriptionChangedAndImageNotChosen }
            type='file'
            accept='image/*'
            onChange={ handleImageFile }
          />
          <Form.Control.Feedback type='invalid'>
            Please upload image
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className='mb-3' controlId='formDescription'>
          <Form.Label>Description</Form.Label>
          <Form.Control
            isInvalid={ !description }
            isValid={ description }
            type='text'
            placeholder='Description'
            value={ description }
            onChange={ event => setDescription(event.target.value) }
          />
        </Form.Group>

        <Button
          disabled={ isAnyFieldInvalid || busy }
          variant='primary'
          type='submit'
          onClick={ handleUpdateHomeCarouselPhoto }
        >
          Save <FontAwesomeIcon icon={ faSave } />
        </Button>

        {isProcessing && (
          <ProgressBar animated now={ fileProgress } />
        )}
      </Form>
    </section>
  );
}

export default AddOrEditHomeCarouselPhotoForm;
