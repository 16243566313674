import { useEffect } from 'react';

import HomeHero from './HomeHero';
import HomeGallery from './HomeGallery';
import HomeCarousel from './HomeCarousel';
import HomeVideos from './HomeVideos';
import HomeUpcomingEvents from './HomeUpcomingEvents';
import HomeChoirs from './HomeChoirs';
import HomeSupport from './HomeSupport';

import './Home.css';

function Home() {

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }, []);

  return (
    <section className='home'>
      <HomeHero />
      <HomeGallery />
      <HomeCarousel />
      <HomeVideos />
      <HomeUpcomingEvents />
      <HomeChoirs />
      <HomeSupport />
    </section>
  );
}

export default Home;
