import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import {
  attemptLogin,
  loginInfo
} from '../../../redux/features/login/loginSlice';
import { authenticated } from '../../../redux/features/authenticated/authenticatedSlice';
import {
  showAppAlert
} from '../../../redux/features/appAlert/appAlertSlice';

import './Login.css';

function Login() {

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(authenticated);
  const loginResult = useSelector(loginInfo);
  const { error, token } = loginResult;

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = event => {
    event.preventDefault();
    dispatch(attemptLogin({ username, password }));
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (error) {
      dispatch(showAppAlert({ message: error.message }));
    } else if (token) {
      dispatch(showAppAlert({
        title: 'Success',
        message: 'login successful',
        bg: 'success'
      }));
      setUsername('');
      setPassword('');
    }
  }, [dispatch, loginResult, error, token]);

  return (
    <section className='login'>
      <Form className={ isAuthenticated ? 'not-allowed' : '' }>
        <Form.Group className="mb-3" controlId="formUsername">
          <Form.Label>Username</Form.Label>
          <Form.Control
            disabled={ isAuthenticated }
            type="text"
            placeholder="Enter username"
            value={ username }
            onChange={ event => setUsername(event.target.value) }
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            disabled={ isAuthenticated }
            type="password"
            placeholder="Password"
            value={ password }
            onChange={ event => setPassword(event.target.value) }
          />
        </Form.Group>

        <Button
          disabled={ isAuthenticated }
          variant="primary"
          type="submit"
          onClick={ handleLogin }
        >
          Login
        </Button>
      </Form>
    </section>
  );
}

export default Login;
