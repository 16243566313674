import { useEffect, useState } from 'react';

import { Outlet, useNavigate, useParams } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useDispatch, useSelector } from 'react-redux';

import { choirsInfo, getChoirs } from '../../../redux/features/choirs/choirsSlice';

import Loading from '../../generic/Loading';

import './Choirs.css';

import flightCrewLogo from '../../../assets/images/flight-crew-logo.png';
import trebleHawksLogo from '../../../assets/images/treble-hawks-logo.png';
import nighthawkHonorsLogo from '../../../assets/images/nighthawk-honors-logo.png';

const choirLogos = {
  'flight-crew': flightCrewLogo,
  'treble-hawks': trebleHawksLogo,
  'nighthawk-honors': nighthawkHonorsLogo,
  'nighthawk-alum': nighthawkHonorsLogo
};

function Choirs() {

  const { choirNameDashed: selectedChoir } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { isLoading, choirs } = useSelector(choirsInfo);

  const [videoPlayingId, setVideoPlayingId] = useState(0);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (!choirs.length) {
      dispatch(getChoirs());
    }
  }, [dispatch, choirs.length]);

  const displayChoirs = () => {
    return choirs.map(choir => {
      return (
        <Tab
          key={ choir.id }
          eventKey={ choir.name.toLowerCase().replace(' ', '-') }
          title={ choir.name }
        >
          <Outlet // Choir component from App.js nested Route
            context={{
              choir,
              videoPlayingId,
              setVideoPlayingId
            }}
          />
        </Tab>
      );
    });
  };

  const handleChoirSelect = eventKey => {
    navigate(eventKey);
  };

  return (
    <>
      {isLoading
        ? <Loading />
        : (
          <section className='choirs'>
            <div className='choirs-heading-and-logo-container'>
              <h2 className='choirs-heading'>Northfield Choirs</h2>
              <img
                className='choir-logo'
                alt={ `${selectedChoir} logo` }
                src={ choirLogos[selectedChoir] }
              />
            </div>
            <Tabs
              defaultActiveKey='nighthawk-honors'
              id='choir-tabs'
              className='mb-3'
              activeKey={ selectedChoir }
              onSelect={ handleChoirSelect }
            >
              { displayChoirs() }
            </Tabs>
          </section>
        )
      }
    </>
  );
}

export default Choirs;
