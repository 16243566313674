import { useEffect } from 'react';

import Button from 'react-bootstrap/Button';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';

import { authenticated } from '../../../../redux/features/authenticated/authenticatedSlice';
import { displayModal } from '../../../../redux/features/modal/modalSlice';
import {
  getEventsByMonths,
  eventsInfo
} from '../../../../redux/features/events/eventsSlice';

import EventsByMonth from '../EventsByMonth';
import Loading from '../../../generic/Loading';

import './EventsList.css';

function EventsList() {

  const dispatch = useDispatch();
  const isAuthenticated = useSelector(authenticated);
  const { isLoading, eventsByMonths } = useSelector(eventsInfo);

  const showAddEventModal = () => {
    const innerComponentName = 'AddEventForm';
    dispatch(displayModal({ innerComponentName }));
  };

  useEffect(() => {
    if (!eventsByMonths.length) {
      dispatch(getEventsByMonths());
    }
  }, [dispatch, eventsByMonths.length]);

  const displayEventsByMonths = () => {
    return eventsByMonths.length
      ? eventsByMonths.map(eventsByMonth => {
        return <EventsByMonth
          key={ eventsByMonth.monthYear }
          eventsByMonth={ eventsByMonth }
        />
      })
      : <h5>Check again soon!</h5>;
  };

  return (
    <div className='events-list-container'>
      <div className='events-list-container-heading-container'>
        <h2 className='events-list-container-heading'>Upcoming</h2>

        {isAuthenticated && (
          <Button
            className='add-event'
            variant='outline-primary'
            onClick={ showAddEventModal }
          >
            <FontAwesomeIcon icon={ faPlus } />
          </Button>
        )}
      </div>

      {isLoading
        ? <Loading />
        : displayEventsByMonths()
      }

    </div>
  );
}

export default EventsList;
