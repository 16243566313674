import Nav from 'react-bootstrap/Nav';

import './VideoNavPill.css';

function VideoNavPill({ videoEventType }) {
  return (
    <Nav.Item>
      <Nav.Link as='button' eventKey={ videoEventType.eventType }>
        { videoEventType.title }
      </Nav.Link>
    </Nav.Item>
  );
}

export default VideoNavPill;
