import { useEffect, useRef, useState } from 'react';

import Button from 'react-bootstrap/Button';
import { faBagShopping } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

import { cartNumberOfItemsStore } from '../../../../redux/features/cart/cartSlice';

import CartDropdown from '../CartDropdown';

import './CartButton.css';

function CartButton() {

  const totalQuantityOfCartItems = useSelector(cartNumberOfItemsStore);

  const cartButtonRef = useRef(null);
  const cartDropdownRef = useRef(null);

  const [isCartDropdown, setIsCartDropdown] = useState(false);

  useEffect(() => {
    const handleClickOutsideCartDropdown = event => {
      const isClickInsideCartButton = cartButtonRef.current &&
        cartButtonRef.current.contains(event.target);

      const isClickOutsideCartDropdown = cartDropdownRef.current &&
        !cartDropdownRef.current.contains(event.target);

      const isClickInsideModal = !!event.target.closest('.modal');

      const isClickInsideCheckoutButton =
        !!event.target.closest('.checkout-button-link');

      const isClickInsideSeeSwagButton =
        !!event.target.closest('.swag-support-button-link');

      if (
        (
          isClickOutsideCartDropdown ||
          isClickInsideCheckoutButton ||
          isClickInsideSeeSwagButton
        ) &&
        !isClickInsideCartButton &&
        !isClickInsideModal &&
        isCartDropdown
      ) {
        setIsCartDropdown(false);
      }
    };

    document.addEventListener('click', handleClickOutsideCartDropdown);

    return () => {
      document.removeEventListener('click', handleClickOutsideCartDropdown);
    };
  }, [cartButtonRef, cartDropdownRef, isCartDropdown]);

  const isCartEmpty = totalQuantityOfCartItems === 0;

  return (
    <div className='cart-nav-container'>
      <Button
        ref={ cartButtonRef }
        className={`cart-button${!isCartEmpty ? ' cart-highlight' : ''}`}
        variant='outline-light'
        onClick={ () => setIsCartDropdown(!isCartDropdown) }
      >
        <p className='cart-number-of-items'>{ totalQuantityOfCartItems }</p>
        <FontAwesomeIcon icon={ faBagShopping } />
      </Button>

      { isCartDropdown && <div className='cart-button-pointer'></div> }

      { isCartDropdown && <CartDropdown ref={cartDropdownRef} /> }
    </div>
  );
}

export default CartButton;
